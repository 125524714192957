import React, { useMemo, useState } from "react";

import {
  SimpleItem,
  Label,
  RequiredRule,
  EmptyItem,
} from "devextreme-react/form";

import {
  EntityAllegatiList,
  EntityEditPage,
  useSvcSelectBoxConfig,
} from "@/svc";

export default function CorsiEditPage() {
  const [clienteSelected, setClienteSelected] = useState(null);
  const [contattiDisabled, setContattiDisabled] = useState(true);

  const contattiSelectBoxConfigFiltri = useMemo(
    () => ({
      clientiRecord: clienteSelected,
    }),
    [clienteSelected]
  );

  const onClientiRecordChanged = ({ value }) => {
    setClienteSelected(value);
    setContattiDisabled(Boolean(value) === false);
  };

  const onDatarowReady = (datarow) => {
    if (datarow.clientiRecord && clienteSelected !== datarow.clientiRecord) {
      setClienteSelected(datarow.clientiRecord);
    }
    setContattiDisabled(Boolean(datarow.clientiRecord) === false);
  };

  const corsiSelectBoxConfig = useSvcSelectBoxConfig({
    domain: "Xtra",
    entity: "corsi",
    paginate: true,
  });
  const contattiSelectBoxConfig = useSvcSelectBoxConfig({
    domain: "Core",
    entity: "contatti",
    disabled: contattiDisabled,
    paginate: true,
    filters: contattiSelectBoxConfigFiltri,
  });
  const clientiSelectBoxConfig = useSvcSelectBoxConfig({
    domain: "Core",
    entity: "clienti",
    paginate: true,
    onValueChanged: onClientiRecordChanged,
  });

  const clienteLabel = process.env.REACT_APP_ENTITY_CLIENTE_ALIAS ?? "Cliente";

  return (
    <EntityEditPage
      domain="core"
      entity="contattiCorsi"
      onDatarowReady={onDatarowReady}
    >
      <EntityEditPage.EditForm>
        {(datarow, setDatarowField) => (
          <React.Fragment>
            <SimpleItem
              dataField="clientiRecord"
              editorType="dxSelectBox"
              colSpan={2}
              editorOptions={clientiSelectBoxConfig}
            >
              <Label text={clienteLabel} />
              <RequiredRule />
            </SimpleItem>

            <SimpleItem
              dataField="contatto"
              editorType="dxSelectBox"
              colSpan={2}
              editorOptions={contattiSelectBoxConfig}
            >
              <Label text="Contatto" />
              <RequiredRule />
            </SimpleItem>
            <SimpleItem
              dataField="corso"
              editorType="dxSelectBox"
              colSpan={2}
              editorOptions={corsiSelectBoxConfig}
            >
              <Label text="Corso" />
              <RequiredRule />
            </SimpleItem>
            <SimpleItem dataField="data_inizio" editorType="dxDateBox">
              <Label text="Data Inizio" />
              <RequiredRule />
            </SimpleItem>
            <SimpleItem dataField="data_fine" editorType="dxDateBox">
              <Label text="Data Fine" />
            </SimpleItem>
            <SimpleItem
              dataField="data_scadenza"
              editorType="dxDateBox"
              editorOptions={{
                readOnly: true,
              }}
            >
              <Label text="Data Scadenza" />
            </SimpleItem>
            <SimpleItem
              colSpan={3}
              dataField="rinnovato"
              editorType="dxCheckBox"
            >
              <Label text="Rinnovato" />
            </SimpleItem>
            <EmptyItem colSpan={3} />
            <SimpleItem colSpan={4}>
              <Label text="Allegati" />
              <EntityAllegatiList
                items={datarow.allegatiList}
                setItems={setDatarowField("allegatiList")}
              />
            </SimpleItem>
          </React.Fragment>
        )}
      </EntityEditPage.EditForm>
    </EntityEditPage>
  );
}
