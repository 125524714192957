import React from 'react';

import {
  Column,
  Lookup,
  RequiredRule
} from 'devextreme-react/data-grid';

import { EntityListPage, useSvcLookupDataGridColumnStore } from '@/svc';

function formatReduction({ value }) {
  const reduction = Number(value);
  if (Number.isNaN(reduction)) {
    return value;
  }

  return reduction > 0 && reduction <= 1
    ? `${Math.round(reduction * 100)} %`
    : `€ ${reduction.toFixed(2)}`;
}

export default function ListinoTipoCameraAziendaPage() {
  const tipoCameraAziendaDataSource = useSvcLookupDataGridColumnStore({
    domain: 'Book',
    entity: 'tipoCameraAzienda',
    lookupKey: 'id'
  });

  return (
    <EntityListPage
      domain='Book'
      entity='listinoTipoCameraAzienda'
      editRoute='/book/ListinoTipoCameraAzienda/edit'>
        <EntityListPage.Columns>
          <Column
            caption='Valido dal'
            dataField='valido_da'
            dataType='date'
            >
              <RequiredRule />
          </Column>

          <Column
            caption='Tipo Camera'
            dataField='tipoCameraAzienda.id'>
              <Lookup
                dataSource={tipoCameraAziendaDataSource}
                displayExpr="tipoCamera.displayText"
                valueExpr="id" />
              <RequiredRule />
          </Column>

          <Column
            caption='Riduzione bambini'
            dataField='bambino_3_6'
            dataType='number'
            customizeText={formatReduction}>
              <RequiredRule />
          </Column>

          <Column
            caption='Riduzione ragazzi'
            dataField='bambino_6_12'
            dataType='number'
            customizeText={formatReduction}>
              <RequiredRule />
          </Column>

          <Column
            caption='Riduzione 3° adulto'
            dataField='riduzione_3'
            dataType='number'
            customizeText={formatReduction}>
              <RequiredRule />
          </Column>

          <Column
            caption='Riduzione 4° adulto'
            dataField='riduzione_4'
            dataType='number'
            customizeText={formatReduction}>
              <RequiredRule />
          </Column>

          <Column
            caption='Riduzione DUS'
            dataField='opzione_dus'
            dataType='number'
            customizeText={formatReduction}>
              <RequiredRule />
          </Column>
        </EntityListPage.Columns>
    </EntityListPage>
  );
}