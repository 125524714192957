import React from 'react';

import { Column } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function CorsiTipologiaPage() {
  return (
    <EntityListPage
      domain='Xtra'
      entity='corsiTipologia'
      editRoute='/xtra/CorsiTipologia/edit'>
        <EntityListPage.Columns>
          <Column
            caption='Nome'
            dataField='displayText'
            dataType='string' />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}