import React from 'react';

import { Label, SimpleItem, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcSelectBoxConfig } from '@/svc';

export default function BookAziendeEditPage() {
  const aziendeSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Auth',
    entity: 'aziende',
    displayExpr: 'ragionesociale',
    paginate: true
  });

  return <EntityEditPage
    domain='Book'
    entity='bookAziende'
    >
      <EntityEditPage.EditForm>
        {(datarow) => {
          return (
            <React.Fragment>
              <SimpleItem
                dataField="azienda"
                editorType="dxSelectBox"
                editorOptions={aziendeSelectBoxConfig}>
                  <Label text="Azienda" />
                  <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField="sync_username"
                editorType="dxTextBox">
                  <Label text="Sync Username" />
              </SimpleItem>
              <SimpleItem
                dataField="sync_password"
                editorType="dxTextBox">
                  <Label text="Sync Password" />
              </SimpleItem>
              <SimpleItem
                dataField="sync_type"
                editorType="dxNumberBox">
                  <Label text="Sync Type" />
              </SimpleItem>
              <SimpleItem
                dataField="id_skivallee"
                editorType="dxTextBox">
                  <Label text="Id Struttura Skivallee" />
              </SimpleItem>
              <SimpleItem
                dataField="ericsoft_propertycode"
                editorType="dxTextBox">
                  <Label text="PropertyCode Ericsoft" />
              </SimpleItem>
              <SimpleItem
                dataField="external_api_key"
                editorType="dxTextBox">
                  <Label text="API Key gestionale esterno" />
              </SimpleItem>
              <SimpleItem
                dataField="external_license_key"
                editorType="dxTextBox">
                  <Label text="Codice Licenza gestionale esterno" />
              </SimpleItem>
              <SimpleItem
                dataField="external_persontype_kids_0_3"
                editorType="dxTextBox">
                  <Label text="Codice Esterno bambini 0-3" />
              </SimpleItem>
              <SimpleItem
                dataField="external_persontype_kids_3_6"
                editorType="dxTextBox">
                  <Label text="Codice Esterno bambini 3-6" />
              </SimpleItem>
              <SimpleItem
                dataField="external_persontype_kids_6_12"
                editorType="dxTextBox">
                  <Label text="Codice Esterno bambini 6-12" />
              </SimpleItem>
              <SimpleItem
                dataField="external_persontype_adult"
                editorType="dxTextBox">
                  <Label text="Codice Esterno Adulti" />
              </SimpleItem>
            </React.Fragment>
          );
        }}
      </EntityEditPage.EditForm>
  </EntityEditPage>
}