import React from 'react';

import { Column } from 'devextreme-react/tree-list';

import { EntityListPage } from '@/svc';

export default function OrganigrammaPage() {
  return (
    <EntityListPage
      domain='HR'
      entity='organigramma'
      editRoute='/hres/Organigramma/edit'
      listComponent={EntityListPage.TreeList}
    >
        <EntityListPage.Columns>
          <Column
            caption='Descrizione'
            dataField='descrizione'
            dataType='string' />
          <Column
            caption='Descrizione Breve'
            dataField='displayText'
            dataType='string' />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}