import { useCallback, useContext } from 'react';

import { SvcContext } from '@/svc';

export default function useSvcExport(domain, entity) {
  const { client, user } = useContext(SvcContext);

  const token = user.token_login;
  const exportEntity = useCallback(() => {
    return client.exportEntity(domain, entity, { token });
  }, [ client, domain, entity, token ]);

  return exportEntity;
}