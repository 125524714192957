import React from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcDatasource } from '@/svc';


export default function ServiziPrenotazioneAziendeEditPage() {
  const aziendeDatasource = useSvcDatasource({
    domain: 'Auth',
    entity: 'aziende'
  });

  const servizioDatasource = useSvcDatasource({
    domain: 'Book',
    entity: 'serviziPrenotazione'
  });

  return <EntityEditPage
    domain='Book'
    entity='serviziPrenotazioneAziende'
  >
    <EntityEditPage.EditForm>
      {(datarow) => (
        <React.Fragment>
          <SimpleItem dataField="azienda" editorType="dxSelectBox" editorOptions={{
            dataSource: aziendeDatasource,
            displayExpr: "ragionesociale"
          }}>
            <Label text="Azienda" />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField="max_persone" editorType="dxNumberBox">
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField="servizio" editorType="dxSelectBox" editorOptions={{
            dataSource: servizioDatasource,
            displayExpr: "nome"
          }}>
            <Label text="Tipo Servizio" />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField="tipoListino" editorType="dxSelectBox" editorOptions={{
            dataSource: [{id: 10, nome: 'Con Riduzioni'}, {id: 20, nome: 'Prezzi Assoluti'}, {id: 30, nome: 'Prezzo Unitario'}],
            displayExpr: "nome",
            valueExpr: "id"
          }}>
            <Label text="Tipo Listino" />
            <RequiredRule />
          </SimpleItem>
          <SimpleItem dataField="wp_product_id" editorType="dxNumberBox">
            <Label text="ID Prodotto Wordpress" />
          </SimpleItem>
        </React.Fragment>
      )}
    </EntityEditPage.EditForm>
  </EntityEditPage>
}