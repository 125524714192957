import React from 'react';

import { Column } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function PermessiPescaPage() {
  return (
    <EntityListPage
      domain='Store'
      entity='permessiPesca'
      editRoute='/store/PermessiPesca/edit'>
        <EntityListPage.Filters
          maxEntries={{
            initialValue: 100
          }}
        />
        <EntityListPage.Columns>
          <Column
            caption='Nome'
            dataField='nome'
            dataType='string'
          />
          <Column
            caption='Categoria'
            dataField='categoriaRecord.displayText'
            dataType='string'
          />
          <Column
            caption='Codice'
            dataField='codice'
            dataType='string'
          />
          <Column
            caption='Importo'
            dataField='importo'
            dataType='number'
          />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}