import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { useHistory } from 'react-router-dom';

function ListRenderer(props, ref) {
  const {
    component: Component,
    editRoute, primaryKey = 'id',
    onEdit, onClone,
    ...rest
  } = props;

  const listCompontentRef = useRef();
  useImperativeHandle(ref, () => ({
    listCompontentRef: listCompontentRef,
    clearSelection: () => {
      listCompontentRef.current.clearSelection();
    }
  }));

  const history = useHistory();
  
  const onEditWrapper = useCallback(async ({ row }) => {
    let cancel = false;
    if (typeof onEdit === 'function') {
      cancel = await onEdit(row);
    }

    if (!cancel) {
      const itemKey = row.data[primaryKey];
      history.push(`${editRoute}/${itemKey}`);
    }
  }, [ history, editRoute, onEdit, primaryKey ]);
  
  const onCloneWrapper = useCallback(async ({ row }) => {
    let cancel = false;
    if (typeof onClone === 'function') {
      cancel = await onClone(row);
    }

    if (!cancel) {
      const itemKey = row.data[primaryKey];
      history.push(`${editRoute}/${itemKey}-clone`);
    }
  }, [ history, editRoute, onClone, primaryKey ]);

  return (
    <Component
      ref={listCompontentRef}
      onEdit={onEditWrapper}
      onClone={onCloneWrapper}
      {...rest}
    />
  );
}

export default forwardRef(ListRenderer);