import React from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage } from '@/svc';


export default function ServiziPrenotazioneEditPage() {
  return <EntityEditPage
    domain='Book'
    entity='serviziPrenotazione'
  >
    <EntityEditPage.EditForm>
      {(datarow) => (
        <React.Fragment>
          <SimpleItem dataField="nome" editorType="dxTextBox">
            <Label text="Nome" />
            <RequiredRule />
          </SimpleItem>
        </React.Fragment>
      )}
    </EntityEditPage.EditForm>
  </EntityEditPage>
}