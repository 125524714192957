import { useMemo } from 'react';

import { useSvcDatasource } from '@/svc';

export default function useSvcSelectBoxConfig(props) {
  const { 
    domain, entity, listOpts = {}, filters, lookupKey = 'id',
    datasourceProps = null,
    paginate = true, nullable = true,
    pageLoadMode = "nextButton", displayExpr = 'displayText',
    searchEnabled = true, searchExpr = [displayExpr], searchMode = 'contains',
    ...rest
  } = props;

  const svcDataSource = useSvcDatasource({
    domain,
    entity,
    listOpts,
    filters,
    paginate,
    lookupKey,
    load: datasourceProps?.load,
    byKey: datasourceProps?.byKey
  });

  const selectBoxConfig = useMemo(() => ({
    dataSource: svcDataSource,
    pageLoadMode,
    displayExpr,
    searchEnabled,
    searchExpr,
    searchMode,
    showClearButton: nullable,
    ...rest
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    svcDataSource,
    pageLoadMode,
    displayExpr,
    searchEnabled,
    searchExpr,
    searchMode,
    nullable
    // Intenzionalmente omesso ""...rest" per evitare che faccia perenne refresh (in quanto iterabile)
  ]);
  return selectBoxConfig;
}
