export default {
  Xtra: {
    childRoutes: [
      {
        enabled: true,
        entity: 'contattiCorsiScadenze',
        heading: 'Tabelle',
        name: 'Scadenze',
        route: 'Corsi/Scadenze',
        icon: 'fa fa-calendar',
        showMenu: true
      }
    ]
  }
};
