import React, { useCallback, useMemo, useState, useRef } from 'react';

import { Label, SimpleItem, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcGet, useSvcSelectBoxConfig } from '@/svc';
import { Button, Form, List, LoadIndicator } from 'devextreme-react';
import { Modal } from '@/ui/components';
import shortid from 'shortid';
import { Toast } from '@/ui/utils';


function AddCorsoInlineButton(props) {
  const { 
    onModalClosed
  } = props;
  const [ modalVisible, setModalVisible ] = useState(false);
  const [{ loading, error, data }] = useSvcGet('Xtra', 'dateFormazioneCorsi');
  const [datarow, setDatarow] = useState(null);
  const setDatarowField = useCallback((fieldName) => (fieldValue) => setDatarow({
    ...datarow,
    [fieldName]: fieldValue
  }), [datarow]);

  const corsiSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Xtra',
    entity: 'corsi',
    paginate: true
  });

  const title = 'Aggiungi un corso';

  const onAddClick = () => {
    setDatarow({
      ...JSON.parse(JSON.stringify(data))
    });
    setModalVisible(true);
  }
  
  const resolveModalValue = (value) => {
    setModalVisible(false);
    onModalClosed(value);
  }

  const onConfirmClick = () => {
    resolveModalValue(datarow);
  }

  const onUndoClick = () => {
    resolveModalValue(void 0);
  }

  let modalBody = void 0;

  if (loading) {
    modalBody = <LoadIndicator visible />
  }
  else if (data && !datarow) setDatarow({
    ...data
  });
  else if (error) {
    modalBody = <span>Si è verificato un errore, contattare l'assistenza.</span>;
  }
  else if (datarow) {
    modalBody = <React.Fragment>
        <Form
          colCount={1}
          formData={datarow}
          id="form">
          <SimpleItem
            dataField='testo_per_sito'
            editorType='dxTextBox'
          >
            <Label text="Testo per sito" />
          </SimpleItem>
          <SimpleItem
            dataField='corso'
            editorType='dxSelectBox'
            editorOptions={corsiSelectBoxConfig}
          >
            <Label text="Corso" />
          </SimpleItem>
        </Form>
    </React.Fragment>
  }

  return <React.Fragment>
    <Button
        icon="add"
        type="success"
        text="Aggiungi"
        onClick={onAddClick}
    />
    <Modal
        visible={modalVisible}
        minWidth={'40vw'}
      >
      <Modal.Title>
        {title}
      </Modal.Title>
      <React.Fragment>
        {modalBody}
      </React.Fragment>
      <Modal.Button type="danger" onClick={onUndoClick}>Annulla</Modal.Button>
      <Modal.Button type="success" onClick={onConfirmClick}>Aggiungi</Modal.Button>
    </Modal>
  </React.Fragment>;
}

function DateFormazioniCorsiListComponent(props) {
  const { items = [], setItems } = props;
  const listRef = useRef();

  const onModalClosed = useCallback((value) => {
    if (value) {
      items.push(value);
      setItems(items);
      listRef.current.instance.reload();
    }
  }, [items, setItems]);

  const newButton = useMemo(() => <AddCorsoInlineButton
    onModalClosed={onModalClosed}
  />, [onModalClosed]);

  const deleteItem = (item) => {
    item.eliminato = true;
    setItems(items);
    Toast('info', 'Elemento contrassegnato come eliminato e verrà definitivamente eliminato al salvataggio. Puoi ripristinarlo cliccando il pulsante "Ripristina".', 6000);
  };

  const restoreItem = (item) =>{
    item.eliminato = false;
    setItems(items);
  };

  if (items) {
    function ItemTemplate(item) {
      if (!item.uuid) item.uuid = shortid();
      return (
        <div style={{textDecoration: item.eliminato ? 'line-through' : ''}} key={item.uuid}>
          {item.testo_per_sito}
          
          <Button
              visible={!item.eliminato}
              style={{ float: 'right' }}
              icon='trash'
              onClick={() => deleteItem(item)}
          />
          <Button
              visible={item.eliminato}
              style={{ float: 'right' }}
              icon='refresh'
              type='success'
              text='Ripristina'
              onClick={() => restoreItem(item)}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <List
          ref={listRef}
          keyExpr="uuid"
          dataSource={items}
          height='auto'
          itemRender={ItemTemplate} />
        <br /> 
        <hr />
        {newButton}
      </React.Fragment>
    );
  } else {
    return <React.Fragment>
      Nessun elemento inserito. <br />
      {newButton}
    </React.Fragment>
  }
}

export default function DateFormazioneEditPage() {
  return (
    <EntityEditPage
      domain='Xtra'
      entity='dateFormazione'>
        <EntityEditPage.EditForm>
          {(datarow, setDatarowField) => (
            <React.Fragment>
              <SimpleItem
                dataField="data_inizio"
                editorType="dxDateBox"
                editorOptions={{
                  type: 'datetime'
                }}
              >
                <Label text="Data Inizio" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField="oggetto"
                editorType="dxTextBox"
              >
                <Label text="Oggetto" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                colSpan={2}
                dataField="note"
                editorType="dxTextBox"
              >
                <Label text="Note" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem colSpan={4}>
                <Label text="Elenco Corsi" />
                <DateFormazioniCorsiListComponent 
                  items={datarow.corsiList}
                  setItems={setDatarowField('corsiList')}
                />
              </SimpleItem>
            </React.Fragment>
          )}
        </EntityEditPage.EditForm>
    </EntityEditPage>
  );
}