import React, { useRef } from 'react';

import TreeList, {
  Editing, HeaderFilter, SearchPanel,
  Scrolling, StateStoring
} from 'devextreme-react/tree-list';

import { useSvcDatasource } from '@/svc';

function onRowPrepared(e) {
  if (e.rowType === 'data' && e.data && e.data.flag_eliminato) {
    e.rowElement.style.textDecoration = 'line-through';
  }
}

export default function EntityTreeList(props) {
  const {
    domain, entity, filters, parentIdExpr = 'padre.id',
    autoExpandAll = true, onDatasourceLoaded, onInitialized,
    mode = 'popup',
    children
  } = props;

  const treeListRef = useRef();
  const dataSource = useSvcDatasource({
    domain, entity, filters,
    onDatasourceLoaded,
    paginate: true
  });

  return (
    <TreeList
      ref={treeListRef}
      showBorders
      columnHidingEnabled
      dataSource={dataSource}
      onInitialized={(e) => onInitialized && onInitialized(e)}
      autoExpandAll={autoExpandAll}
      parentIdExpr={parentIdExpr}
      onRowPrepared={onRowPrepared}
    >
      <StateStoring
        enabled={true}
        type="sessionStorage"
        storageKey={entity + '_treelist_store'}
      />
      <SearchPanel
        visible={true}
        highlightCaseSensitive={true}
      />
      <HeaderFilter visible={true} />
      <Editing
        allowUpdating={true}
        mode={mode}
      />
      <Scrolling mode="standard" />
      {children}
    </TreeList>
  );
}