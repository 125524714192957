/**
 * Questo file contiene un mapping tra le entities e le routes di react.
 * Ad ogni entity corrisponde una route.
 * 
 * Il mapping è necessario a seguito della V2 del menu dove, di fatto, scende
 * un menu lineare (array di routes).
 */
export default {
  'aziende': '/auth/Aziende',
  'bookAziende': '/book/BookAziende',
  'utenti': '/auth/Utenti',
  'prenotazioni': '/book/Prenotazioni',
  'listinoPeriodi': '/book/ListinoPeriodi',
  'listinoTipoCameraAzienda': '/book/ListinoTipoCameraAzienda',
  'nomineOrganigramma': '/hres/NomineOrganigramma',
  'organigramma': '/hres/Organigramma',
  'periodiImpostazioni': '/book/PeriodiImpostazioni',
  'riepilogoPrenotazioni': '/book/RiepilogoPrenotazioni',
  'serviziPrenotazione': '/book/ServiziPrenotazione',
  'serviziPrenotazioneAziende': '/book/ServiziPrenotazioneAziende',
  'tipoCamera': '/book/TipoCamera',
  'tipoCameraAzienda': '/book/TipoCameraAzienda',
  'tipoCameraDisponibilita': '/book/TipoCameraDisponibilita',
  'clienti': '/core/Clienti',
  'clientiCategorie': '/core/ClientiCategorie',
  'contatti': '/core/Contatto',
  'contattiClientiDati': '/core/ContattiClientiDati',
  'contattiCorsi': '/core/ContattiCorsi',
  'contattiMarketing': '/core/ContattoMarketing',
  'corsi': '/xtra/Corsi',
  'corsiTipologia': '/xtra/CorsiTipologia',
  'contattiCorsiScadenze': '/xtra/Corsi/Scadenze',
  'dateFormazione': '/xtra/DateFormazione',
  'permessiPesca': '/store/PermessiPesca',
  'formazioneCorsi': '/xtra/FormazioneCorsi'
}
