import React, { useMemo, useRef, forwardRef, useImperativeHandle } from 'react';

import Form, { SimpleItem, RequiredRule, Label } from 'devextreme-react/form';

const UtentiChangePasswordForm = forwardRef((props, ref) => {
  const { datarow } = props;
  const formRef = useRef();

  const passwordTextBoxConfig = useMemo(() => ({
    mode: 'password'
  }), []);

  useImperativeHandle(ref, () => ({
    validateForm() {
      return formRef.current.instance.validate();
    }
  }));

  return <Form
    colCount={1}
    formData={datarow}
    id="changePasswordForm"
    ref={formRef}>
      <SimpleItem dataField="newPassword1" editorType="dxTextBox" editorOptions={passwordTextBoxConfig}>
        <Label text="Nuova Password" />
        <RequiredRule />
      </SimpleItem>
      <SimpleItem dataField="newPassword2" editorType="dxTextBox" editorOptions={passwordTextBoxConfig}>
        <Label text="Conferma Nuova Password" />
        <RequiredRule />
      </SimpleItem>
  </Form>;
});
export default UtentiChangePasswordForm;