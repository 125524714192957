import React from 'react';

import { SvcSelectBox } from '@/svc/components';

export default function CorsiTipologiaRecordFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  const onValueChanged = ({value}) => {
    setValue(value);
  }

  return (
    <SvcSelectBox
      domain='Xtra'
      entity='corsiTipologia'
      value={value}
      setValue={onValueChanged}
    />
  );
}

CorsiTipologiaRecordFilter.key = 'corsiTipologiaRecord';
CorsiTipologiaRecordFilter.label = 'Tipologia Corso';
