import React from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcSelectBoxConfig } from '@/svc';

export default function PermessiPescaEditPage() {
  const categoriaSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Store',
    entity: 'categorie',
    displayExpr: 'descrizione',
    paginate: true
  });

  return (
    <EntityEditPage
      domain='Store'
      entity='permessiPesca'>
        <EntityEditPage.EditForm>
          {(datarow) => (
            <React.Fragment>
              <SimpleItem
                dataField="nome"
                editorType="dxTextBox"
              >
                <Label text="Nome" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField="codice"
                editorType="dxTextBox"
              >
                <Label text="Codice" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField='categoriaRecord'
                editorType='dxSelectBox'
                editorOptions={categoriaSelectBoxConfig}
              >
                <Label text='Categoria' />
              </SimpleItem>
              <SimpleItem dataField="importo" editorType="dxNumberBox">
                <Label text="Importo" />
                <RequiredRule />
              </SimpleItem>
            </React.Fragment>
          )}
        </EntityEditPage.EditForm>
    </EntityEditPage>
  );
}