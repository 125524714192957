import React, { useMemo } from "react";

import "./card.scss";

import Form, { Item, Label } from "devextreme-react/form";

export default function CardFilters(props) {
  const { filters, updateFilters, items } = props;

  const BodyComponent = useMemo(() => {
    return (
      <Form
        colCount="auto"
        minColWidth={300}
        labelLocation="top"
        formData={filters}
      >
        {items.map(({ Component, label }) => {
          return (
            <Item
              colSpan={Component?.colSpan ?? 1}
              key={Component.key}
              dataField={Component.filtersKey ?? Component.key}
              editorOptions={{
                value: filters[Component.key],
                setValue: (value) =>
                  updateFilters({
                    ...filters,
                    [Component.filtersKey ?? Component.key]: value,
                  }),
              }}
              component={Component}
            >
              <Label text={label ?? Component.label} />
            </Item>
          );
        })}
      </Form>
    );
  }, [items, filters, updateFilters]);

  return (
    <div className={"dx-card filter-card content"}>
      <div className={"header"}>
        <div className={"title"}>Filtri</div>
      </div>
      {BodyComponent}
    </div>
  );
}
