import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { LoginForm } from '@/ui/components';
import { SingleCard } from './templates';

export default function NotAuthedLayout() {
  const logoExists = process.env.REACT_APP_LOGO;
  return (
    <Switch>
      <Route exact path='/login' >
        <SingleCard title={logoExists !== void 0 ? '' : 'Accedi'}>
          <LoginForm />
        </SingleCard>
      </Route>
      <Redirect to={'/login'} />
    </Switch>
  );
}