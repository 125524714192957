import React, { useCallback, useState, useRef }  from 'react';

import DataGrid, { Scrolling, SearchPanel, Selection } from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';

function selectItems(componentRef, value, valueExpr) {
  if (Array.isArray(value)) {
    const selectedIndexes = value.reduce((acc, next) => {
      acc.push(next[valueExpr])
      return acc;
    }, []);
    componentRef.selectRows(
      selectedIndexes
    );
  } else {
    if (value) {
      componentRef.selectRows([value[valueExpr]]);
    }
  }
}

export default function DatagridSelectBox(props) {
  const {
    value: initialValue, onValueChanged, 
    dataSource, displayExpr = 'displayText', valueExpr, placeholder, multiple,
    ColumnsComponents,
    editorOptions = {}
  } = props;

  const [ value, setValue ] = useState(initialValue);
  const datagridRef = useRef();

  const onContentReady = useCallback(e => {
    if (e.component) {
      selectItems(e.component, value, valueExpr)
    }
  }, [ value, valueExpr ]);

  const itemSelectionChanged = useCallback(e => {
    const selectedItems = e.component.getSelectedRowsData();

    if (typeof onValueChanged === 'function') {
      if (multiple) {
        onValueChanged(selectedItems);
      }
      else {
        if (selectedItems && selectedItems.length > 0) {
          onValueChanged(selectedItems[0]);
        }
        else setValue(null); 
      }
    }

    setValue(selectedItems);    
    
  }, [ setValue, onValueChanged, multiple ]);

  const datagridRender = useCallback(() => {
    return (
      <DataGrid
        dataSource={dataSource}
        ref={datagridRef}
        keyExpr={valueExpr}
        onContentReady={onContentReady}
        onSelectionChanged={itemSelectionChanged}
        showBorders={true}
        height='90%'
        {...editorOptions}
      >
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Scrolling mode="virtual" />
        <Selection 
          mode={multiple ? 'multiple' : 'single'}
          showCheckBoxesMode={multiple ? 'always' : 'onClick'}
        />
        {ColumnsComponents}
      </DataGrid>
    );
  }, [
    dataSource, multiple, datagridRef, valueExpr, 
    onContentReady, itemSelectionChanged, ColumnsComponents,
    editorOptions
  ]);

  const syncGridSelection = useCallback(e => {
    setValue(e.value);

    if (typeof onValueChanged === 'function') {
      onValueChanged(e.value);
    }

    if (!datagridRef.current) return;

    if (!e.value) {
      datagridRef.current.instance.unselectAll();
    } else {
      selectItems(datagridRef.current?.instance(), e.value, valueExpr);
    }
  }, [ setValue, onValueChanged, datagridRef, valueExpr ]);
  
  return (
    <DropDownBox
      value={value}
      valueExpr={valueExpr}
      displayExpr={displayExpr}
      placeholder={placeholder}
      showClearButton={true}
      dataSource={dataSource}
      onValueChanged={syncGridSelection}
      contentRender={datagridRender}
      dropDownOptions={{
        minHeight: '50vh'
      }}
    />
  );
}