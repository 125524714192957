import { useContext, useMemo } from 'react';

import { useAuth, SvcContext } from '@/svc';

export default function useSvcAziendaType() {
  const { aziendaAs } = useContext(SvcContext);
  const { user } = useAuth();

  return useMemo(() => {
    let type = user?.authAziendeRecord?.tipo;

    if (user.IsAdmin) {
      type = aziendaAs?.tipo;
    }

    type = type ?? 0;

    return {
      isInvalid: type === 0,

      isHotel: type === 1,
      isBB: type === 2,
      isAppartamenti: type === 3,
      
      isServizio: type >= 100,
      isScuolaSci: type === 100,
      isNoleggio: type === 101      
    }
  }, [ aziendaAs, user ]);
}