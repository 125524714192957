import React, { useMemo } from "react";

import { Lookup } from "devextreme-react";

export default function FlagEliminatoFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  const items = useMemo(
    () => [
      {
        v: null,
        text: "Mostra tutto",
      },
      {
        v: false,
        text: "Nascondi righe eliminate",
      },
      {
        v: true,
        text: "Mostra solo righe eliminate",
      },
    ],
    []
  );

  return (
    <Lookup
      items={items}
      displayExpr="text"
      valueExpr="v"
      value={value}
      onValueChanged={(e) => {
        setValue(e.value);
      }}
    />
  );
}

FlagEliminatoFilter.label = "Elementi eliminati";
FlagEliminatoFilter.key = "flag_eliminato";
