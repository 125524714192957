import React, {
  createContext,
  useCallback,
  useEffect,
  useState
} from 'react';

import shortid from 'shortid';

import { useAuth, entityRouteMappings } from '@/svc';

export const NavContext = createContext({});

export function NavProvider(props) {
  const {
    filters: initialFilters = [],
    homeRoute: initialHomeRoute,
    tree: initialNavTree = [],
    staticRoutes = {}
  } = props;

  const { user } = useAuth();
  
  const [ currentRoute, setCurrentRoute ] = useState();
  const [ filters, setFilters ] = useState(initialFilters);
  const [ homeRoute, setHomeRoute ] = useState(initialHomeRoute);
  const [ navData, setNavData ] = useState({});
  const [ entityTitleMap, setEntityTitleMap ] = useState({});
  const [ navTree, _setNavTree ] = useState(initialNavTree);

  const stringifiedFilters = JSON.stringify(filters);

  const setNavTree = useCallback(newNavTree => {
    if (!filters?.length) {
      return _setNavTree(newNavTree);
    }

    function filterRoutes(route) {
      if (route.items == null) {
        return filters.includes(route.path);
      }

      route.items = route.items?.filter(filterRoutes);
      return route;
    }

    function checkAssignCurrentRoute(routeItem) {
      if (routeItem.path === homeRoute) {
        setCurrentRoute(routeItem);
      }
    }

    let navRoutes = [], mainRoutes = [];

    for (let route of newNavTree) {
      const { entity, showMenu } = route;
      if (!entityRouteMappings[entity] && showMenu === true) {
        console.warn(
          `Attenzione: mapping su client inesistente per la route ${entity}`
        );
      }
      else {
        if (showMenu === true) {
          mainRoutes.push({
            ...route,
            path: entityRouteMappings[entity]
          });
        } 
      }
    }

    // Merge tra routes statiche e routes utente.
    /*
    Object.entries(staticRoutes).forEach(([moduleName, { childRoutes }]) => {
      newNavTree[moduleName] = newNavTree[moduleName] || {
        childRoutes: []
      };
      childRoutes.forEach(childRoute => {
        if (!newNavTree[moduleName].childRoutes.find(i => 
          i.name === childRoute.name && i.entity === childRoute.entity
        )) {
          newNavTree[moduleName].childRoutes.push(childRoute);
        }
      });      
      //newNavTree[moduleName].childRoutes.push(...childRoutes);
    });
    */
    
    // Config di progetto per raggruppare per heading le voci di menu.
    // In questo caso, è necessario scorrere tutte le voce di menu ed effettuare un grouping per heading.
    const groupByHeading = process.env.REACT_APP_SIDE_MENU_GROUP_BY_HEADING === '1';

    if (groupByHeading) {
      const navRoutesByHeading = {};
      for (let { 
        entity, heading, name, menuName, 
        pageTitle,
        flag_readonly, path, icon
      } of mainRoutes) {
        heading = heading || 'Altro';
        navRoutesByHeading[heading] = navRoutesByHeading[heading] || {
          entity,
          uuid: shortid.generate(),
          text: heading,
          icon: process.env.REACT_APP_SIDE_MENU_GROUP_BY_HEADING_ROOT_ICON,
          path: void 0,
          items: []
        };

        const childRoute = {
          entity,
          uuid: shortid.generate(),
          text: menuName || name,
          pageTitle: pageTitle,
          icon: icon,
          path,
          readOnly: flag_readonly
        };
        checkAssignCurrentRoute(childRoute);
        navRoutesByHeading[heading].items.push(childRoute);
      }
      navRoutes = Object.values(navRoutesByHeading);
    }
    else {
      navRoutes = mainRoutes
        .map(({
          entity, menuName, name, pageTitle, icon, path, flag_readonly
        }) => {
          const childRoute = {
            entity,
            uuid: shortid.generate(),
            text: menuName || name,
            pageTitle: pageTitle,
            icon: icon,
            path,
            readOnly: flag_readonly
          };
          checkAssignCurrentRoute(childRoute);
          return childRoute;
        });
    }

    const filteredNavTree = navRoutes.map(filterRoutes).filter(({items}) => {
      return items.length > 0
    });

    // Fill dei titoli per le entities.
    const entitiesTitleMap = filteredNavTree.reduce((acc, {items}) => {
      items.forEach(({entity, pageTitle, text}) => {
        acc[entity] = pageTitle || text;
      });
      return acc;
    }, {});
    setEntityTitleMap(entitiesTitleMap);
    _setNavTree(filteredNavTree);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ stringifiedFilters, _setNavTree ]);

  const routes = user?.menuList;
  useEffect(() => {
    if (!routes) {
      return;
    }
    setNavTree(routes);
  }, [ routes, setNavTree ]);

  const ctxValue = {
    currentRoute, setCurrentRoute,
    entityTitleMap,
    filters, setFilters,
    homeRoute, setHomeRoute,
    navData, setNavData,
    navTree, setNavTree
  };
  return <NavContext.Provider value={ctxValue} children={props.children} />;
}