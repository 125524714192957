import React from 'react';

import { TextBox } from 'devextreme-react';

export default function GenericTextFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  return (
    <TextBox
      value={value}
      onValueChanged={({ value }) => {
        setValue(value);
      }}
      showClearButton={true}
    />
  );
}

GenericTextFilter.key = 'genericText';
GenericTextFilter.label = 'Ricerca Globale';
 