import notify from 'devextreme/ui/notify';

/**
 * Mostra un toaster all'interno della pagina in base almessaggio, al tipo ed alla durata specificata.
 *
 * @export
 * @param {'info'|'success'|'error'} [type='info'] Tipologia del toast (info, error, success)
 * @param {*} message Messaggio da mostrare nel toast.
 * @param {number} [duration=2500] Durata del messaggio.
 * @returns
 */
export default function Toast(type = 'info', message, duration = 2500) {
  return notify(message, type, duration);
}
