import React from 'react';

import { TextBox } from 'devextreme-react';

export default function GiorniFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  return (
    <TextBox
      value={value}
      onValueChanged={({ value }) => {
        if (value) {
          setValue(Number(value));
        }
        else {
          setValue(void 0);
        }
      }}
      showClearButton={true}
    />
  );
}

GiorniFilter.key = 'giorni';
GiorniFilter.label = 'Scadenza entro XX giorni';