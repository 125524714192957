import './riepilogo-prenotazioni.scss';

import React from 'react';

import Toolbar, { Item } from 'devextreme-react/toolbar';
import { DateTime } from 'luxon';

import { EntityScheduler } from '@/svc';

function transformDataSource(dataSource) {
  const newDataSource = [];

  dataSource.forEach(({ data, infoPrenotazioni }) => {
    infoPrenotazioni.forEach(({ Value: camera }) => {
      newDataSource.push({
        ...camera,
        startDate: data,
        allDay: true
      });
    });
  });

  return newDataSource;
}

function computeFilters(currentDate, view) {
  console.info(currentDate);
  const dt = DateTime.fromJSDate(currentDate)
  return {
    data_inizio: dt.minus({ days: 45 }).startOf('day'),
    data_fine: dt.plus({ days: 45 }).startOf('day')
  };
}

export default function RiepilogoPrenotazioniPage() {
  return (
    <React.Fragment>
      <Toolbar style={{ backgroundColor: 'transparent' }}>
        <Item 
          location="before"
          locateInMenu="never"
          render={() => (
          <div className="toolbar-label">Riepilogo Prenotazioni</div>
          )}
        />
      </Toolbar>
      <EntityScheduler
        domain='Book'
        entity='riepilogoPrenotazioni'
        filters={computeFilters}
        transformDataSource={transformDataSource}
        appointmentComponent={BookingAppointment}
        appointmentTooltipComponent={BookingAppointmentTooltip} />
    </React.Fragment>
  );
}

function BookingAppointment(props) {
  const data = props.data.appointmentData;

  let type, count;
  if (data?.servizio) {
    type = data.servizio.servizio.displayText;
    count = data.servizio.max_persone;
  } else {
    type = data.tipoCamera.tipoCamera.displayText;
    count = data.tipoCamera.num_camere;
  }

  const available = data.postiDisponibili;
  const busy = (count - available);

  return (
    <React.Fragment>
      {`${available} ${type} disponibili`}
    </React.Fragment>
  );
}

function BookingAppointmentTooltip(props) {
  const data = props.data.appointmentData;

  let type, count, available;
  if (data?.servizio) {
    type = data.servizio.servizio.displayText;
    count = data.servizio.max_persone;
  } else {
    type = `Camera ${data.tipoCamera.tipoCamera.displayText}`;
    count = data.tipoCamera.num_camere;
    available = data.postiDisponibili;
  }

  // const busy = (count - data.postiDisponibili);
  const bookings = data.prenotazioni;
  const date = data.startDate;

  return (
    <div className='tooltip' onClick={e => e.stopPropagation()}>
      <div className='tooltip-title'>
        <div className='tooltip-title-date'>
          <div className='tooltip-title-date__day'>
            {DateTime.fromISO(date).toFormat('dd')}
          </div>
          <div className='tooltip-title-date__month'>
            {DateTime.fromISO(date).toFormat('MMM').toUpperCase()}
          </div>
        </div>
        <div className='tooltip-title-info'>
          <div className='tooltip-title-info__type'>{type}</div>
          <div className='tooltip-title-info__busy'>
            {`${available} disponibili`}
          </div>
        </div>
      </div>

      <div className='tooltip-content'>
        {bookings.map(({ id, prima_notte, ultima_notte }) => {
          const start = DateTime.fromISO(prima_notte).toFormat('d MMM');
          const end = DateTime.fromISO(ultima_notte).toFormat('d MMM');

          return (
            <div key={id} className='tooltip-content-booking'>
              <div className='tooltip-content-booking__title'>
                Prenotazione {id}
              </div>
              <div className='tooltip-content-booking__date'>
                <span>{start}</span>
                <span className='tooltip-content-booking__date-div'>→</span>
                <span>{end}</span>
              </div>
            </div>
          );
        })}
        {bookings.length > 2 && <div className='tooltip-content__more'>↓</div>}
      </div>
    </div>
  );
}