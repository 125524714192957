import React from 'react';

import { Column } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function NomineOrganigrammaPage() {
  const clienteAlias = (process.env.REACT_APP_ENTITY_CLIENTE_ALIAS || 'Cliente');
  return (
    <EntityListPage
      domain='HR'
      entity='nomineOrganigramma'
      editRoute='/hres/NomineOrganigramma/edit'>
        <EntityListPage.Columns>
          <Column
            caption={clienteAlias}
            dataField='clientiRecord.displayText'
            dataType='string' />
          <Column
            caption='Contatto'
            dataField='contatto.displayText'
            dataType='string' />
          <Column
            caption='Ruolo'
            dataField='ruolo.displayText'
            dataType='string' />
          <Column
            caption='Data Nomina'
            dataField='data_nomina'
            dataType='date' />
          <Column
            caption='Scadenza Nomina'
            dataField='scadenza_nomina'
            dataType='date' />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}