import React from 'react';

import { TextBox } from 'devextreme-react';

export default function CodiceFiscaleFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  return (
    <TextBox
      value={value}
      onValueChanged={({ value }) => {
        setValue(value);
      }}
      showClearButton={true}
    />
  );
}

CodiceFiscaleFilter.key = 'codice_fiscale';
CodiceFiscaleFilter.label = 'Codice Fiscale';
 