import React, { useEffect, useState }  from 'react';
import './sms-text-box.css';

import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';

export default function SmsTextBox(props) {
  const {
    value, valueChanged,
    onSendSmsButtonClick,
    editorOptions = {}
  } = props;

  const [displaySendSmsButton, setDisplaySendSmsButton] = useState(void 0);

  useEffect(() => {
    if (value && value.length > 0) {
      setDisplaySendSmsButton(true);
    }
    else {
      setDisplaySendSmsButton(false)
    }
  }, [value]);

  return <>
    <TextBox
      className='sms-text-box'
      {
        ...editorOptions
      }
      value={value}
      onValueChanged={({ value }) => valueChanged && valueChanged(value)}
      >
      {displaySendSmsButton && <TextBoxButton
        name="sms"
        location="after"
        options={{
          icon: 'email',
          onClick: (e) => onSendSmsButtonClick(e)
        }}
      />}
    </TextBox>
  </>
}