import './login-form.scss';

import React, { useState, useRef, useCallback } from 'react';

import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import Form, {
  ButtonItem,
  ButtonOptions,
  Item,
  Label,
  RequiredRule
} from 'devextreme-react/form';

import { useAuth } from '@/svc';
import { useEnv } from '@/ui/hooks';

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Username', mode: 'text' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };

export default function LoginForm() {
  const { signIn } = useAuth();
  const { logo } = useEnv();
  const [ loading, setLoading ] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();

    const { email, password } = formData.current;
  
    setLoading(true);
    signIn(email, password).catch(err => {
      setLoading(false);
      notify(err.message, 'error', 2000);
    })
  }, [ signIn ]);

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      {logo && <img style={{margin: '0 auto', display: 'block'}} src={logo} alt="logo" />}
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Il nome utente è obbligatorio" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="La password è richiesta" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Accedi'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

