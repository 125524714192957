import React from 'react';

import { Column } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function FormazioneCorsiPage() {  
  return (
    <EntityListPage
      domain='Xtra'
      entity='formazioneCorsi'
      editRoute='/xtra/FormazioneCorsi/edit'>
        <EntityListPage.Filters
          maxEntries={{
            initialValue: 100
          }}
        />
        <EntityListPage.Columns>
          <Column
            caption='Data Inizio'
            dataField='data_inizio'
            dataType='date' />
          <Column
            caption='Corso'
            dataField='corso.displayText'
            dataType='string' />
          <Column
            caption='Completo?'
            dataField='al_completo'
            dataType='boolean' />
        </EntityListPage.Columns>
    </EntityListPage>
  );
}
