import './header.scss';

import React, { useContext } from 'react';

import { SelectBox } from 'devextreme-react';
import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useHistory } from 'react-router-dom';

import { SvcContext, useSvcSelectBoxConfig } from '@/svc';
import UserPanel from './user-panel/user-panel';

export default function Header ({ menuToggleEnabled, title, toggleMenu }) {
  const displayAzienda = process.env.REACT_APP_USE_MULTI_AZIENDA === '1';
  const { user, setAziendaAs } = useContext(SvcContext);

  const history = useHistory();

  const aziendaSelectBox = useSvcSelectBoxConfig({
    domain: 'Auth',
    entity: 'aziende',
    onValueChanged: (e) => {
      setAziendaAs(e.value);

      // Hack in order to refresh the page component (but not the actual page)
      history.push('/reload');
      history.goBack();
    },
    displayExpr: 'ragionesociale',
    width: 280
  });

  const aziendaAsSelect = (
    <Item
      visible={displayAzienda}
      location='after'
      editorType='dxSelectBox'
    >
      <SelectBox
        {...aziendaSelectBox}
      />
    </Item>
  );

  return (
    <header className='header-component'>
      <Toolbar className='header-toolbar'>
        <Item
          visible={menuToggleEnabled}
          location='before'
          widget='dxButton'
          cssClass='menu-button'>
            <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location='before'
          cssClass='header-title'
          text={title}
          visible={!!title} />
        {user.IsAdmin && aziendaAsSelect}
        <Item
          location='after'
          locateInMenu='auto'
          menuItemTemplate='userPanelTemplate'>
            <Button
              className='user-button authorization'
              width={210}
              height='100%'
              stylingMode='text'>
                <UserPanel menuMode='context' />
            </Button>
        </Item>
        <Template name='userPanelTemplate'>
          <UserPanel menuMode='list' />
        </Template>
      </Toolbar>
    </header>
  );
}