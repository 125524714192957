import React from 'react';

import { Column, RequiredRule } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function BookAziendePage() {
  return (
    <EntityListPage
      domain='Book'
      entity='bookAziende'
      editRoute='/auth/BookAziende/edit'
    >
      <EntityListPage.Columns>
        <Column
          caption='Azienda'
          dataField='azienda.ragionesociale'
          dataType='string'>
            <RequiredRule />
        </Column>
        <Column
          caption='Id Struttura Skivallee'
          dataField='id_skivallee'
          dataType='string'>
        </Column>
      </EntityListPage.Columns>
    </EntityListPage>
  );
}
