import { useMemo, useContext } from 'react';

import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

import { SvcContext } from '@/svc';

export function scaffoldSvcDatasource(props) {
  const { 
    client, user,
    domain, entity, listOpts = {}, filters, onDatasourceLoaded, 
    paginate = false, pageSize = 10, 
    lookupKey = 'id',
    byKey, load
  } = props;

  const token = user.token_login;

  const defaultByKey = function(item) {
    let restLookupkey = item;

    if (typeof item !== 'number' && typeof item !== 'string') {
      restLookupkey = item[lookupKey];
    }

    return client.getEntity(domain, entity, restLookupkey, { token })
      .then(entity => {
        // TODO: Da pulire
        if (entity?.flag_eliminato) {
          if (entity?.tipoCamera) {
            entity.tipoCamera.displayText = `${entity?.tipoCamera?.displayText} (Eliminato)`;
          } else {
            entity.displayText = `${entity?.displayText} (Eliminato)`;
          }
        }

        return entity;
      })
      .catch(err => {
        console.warn(`[entityGet] per [entity=${entity}] e [key=${restLookupkey}] fallita. Tento con una chiamata list.`);

        return client.listEntity(domain, entity, { longList: [ restLookupkey ] }, { token, ...listOpts })
          .then(([data, list]) => {
            const entity = list.find(i => i[lookupKey] === restLookupkey);
            if (entity?.flag_eliminato) {
              entity.displayText += ' (Eliminato)'
            }
            return entity;
          });
      });
  };

  const defaultLoad = async () =>  {
    const [ data, entities ] = await client
      .listEntity(domain, entity, filters, { token, ...listOpts });
    if (entities.length) {
      for (const entity of entities) {
        if (entity?.flag_eliminato) {
          entity.displayText += ' (Eliminato)'
        }
      }
    }
    onDatasourceLoaded && onDatasourceLoaded(data, entities);
    return entities;
  };

  return new DataSource({
    store: new CustomStore({
      key: lookupKey,
      loadMode: 'raw',
      byKey: byKey || defaultByKey,
      load: load || defaultLoad
    }),
    paginate,
    pageSize
  });
}

export default function useSvcDatasource(props) {
  const { 
    domain, entity, listOpts = {}, filters, onDatasourceLoaded,
    paginate = false, pageSize = 10, lookupKey = 'id',
    load, byKey
  } = props;


  const { client, user } = useContext(SvcContext);
  const token = user.token_login;

  const stringifiedFilters = JSON.stringify(filters);

  const dataSource = useMemo(() => {
    return scaffoldSvcDatasource({
      client, user,
      domain, entity, listOpts, filters, onDatasourceLoaded, 
      paginate, pageSize, 
      lookupKey,
      load, byKey
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ 
    client, user, load, byKey, lookupKey, token, domain, entity, listOpts, 
    paginate, stringifiedFilters 
  ]);

  return dataSource;
}
