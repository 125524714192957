import "./entity-edit-page.scss";

import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { LoadPanel } from "devextreme-react";
import Form from "devextreme-react/form";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory, useParams } from "react-router-dom";

import { useSvcDelete, useSvcGet, useSvcSave } from "@/svc";
import { ErrorPage } from "@/ui/pages";
import { useModalAlert, useModalConfirm } from "@/ui/components";
import { Toast } from "@/ui/utils";
import { NavContext } from "@/navigation";

export default function EntityEditPage(props) {
  const {
    domain,
    entity,
    entityKey = "id",
    title,
    onDatarowReady,
    onBeforeSave,
    customNewCommandValue,
    customSaveCommandValue,
    customSaveRecordKey,
    children,
  } = props;

  const history = useHistory();

  const { currentRoute, entityTitleMap } = useContext(NavContext);

  let { key } = useParams();
  let clone = false;
  if (key?.indexOf("-clone") > 0) {
    key = key.split("-")[0];
    clone = true;
  }

  const [{ loading, error, data }] = useSvcGet(domain, entity, key, {
    clone,
    customNewCommandValue,
  });
  const [datarow, setDatarow] = useState(void 0);

  const [confirmDeleteModal, openConfirmDeleteModal] = useModalConfirm({
    title: "Attenzione",
    question: "Confermi di voler eliminare questo elemento?",
  });
  const [alertModal, openAlertModal, setAlertBody] = useModalAlert({
    title: "Attenzione",
  });

  const [confirmRestoreModal, openConfirmRestoreModal] = useModalConfirm({
    title: "Conferma",
    question: "Vuoi ripristinare questo elemento?",
  });

  /**
   * Mostra un messaggio di errore all'interno della modale.
   */
  const displayError = useCallback(
    async (message) => {
      setAlertBody(message);
      await openAlertModal();
      return;
    },
    [openAlertModal, setAlertBody]
  );

  const setDatarowField = useCallback(
    (fieldName) => (fieldValue) => {
      setDatarow({
        ...datarow,
        [fieldName]: fieldValue,
      });
    },
    [datarow, setDatarow]
  );

  const saveEntity = useSvcSave(domain, entity, {
    customSaveRecordKey,
    customSaveCommandValue,
  });
  const deleteEntity = useSvcDelete(domain, entity);

  const formRef = useRef();

const save = useCallback(async () => {
  const { isValid } = validateForm();
        let save = true;
        if (isValid) {
          if (onBeforeSave) {
            let onBeforeSaveCallRes = onBeforeSave();
            if (onBeforeSaveCallRes && onBeforeSaveCallRes.then) {
              save = await onBeforeSaveCallRes;
            } else {
              save = onBeforeSaveCallRes;
            }
          }
          if (save) {
            try {
              const entityID = await saveEntity(datarow);
              Toast("success", "Elemento salvato con successo!", 1500);
              history.goBack();
            } catch (e) {
              await displayError(e.message);
            }
          }
        }
},[datarow, displayError, history, onBeforeSave, saveEntity])

  // gestione save.
  const saveButtonOptions = useMemo(
    () => ({
      icon: "save",
      onClick: async () => {
        const { isValid } = validateForm();
        let save = true;
        if (isValid) {
          if (onBeforeSave) {
            let onBeforeSaveCallRes = onBeforeSave();
            if (onBeforeSaveCallRes && onBeforeSaveCallRes.then) {
              save = await onBeforeSaveCallRes;
            } else {
              save = onBeforeSaveCallRes;
            }
          }
          if (save) {
            try {
              const entityID = await saveEntity(datarow);
              Toast("success", "Elemento salvato con successo!", 1500);
              history.goBack();
            } catch (e) {
              await displayError(e.message);
            }
          }
        }
      },
    }),
    [datarow, displayError, history, onBeforeSave, saveEntity]
  );

  const deleteButtonOptions = useMemo(
    () => ({
      icon: "fa fa-trash",
      onClick: async () => {
        const confirmDelete = await openConfirmDeleteModal();
        if (confirmDelete) {
          try {
            await deleteEntity(data);
            history.goBack();
          } catch (e) {
            await displayError(e.message);
          }
        }
      },
    }),
    [data, history, deleteEntity]
  );

  const restoreButtonOptions = useMemo(
    () => ({
      icon: "fa fa-undo",
      onClick: async () => {
        const confirmRestore = await openConfirmRestoreModal();
        if (confirmRestore) {
          datarow.flag_eliminato = false; // non uso setDatarow perché chiamo la save prima che lo state venga aggiornato
          await save();
        }
      },
    }),
    [save]
  );

  // gestione back.
  const backButtonOptions = useMemo(
    () => ({
      type: "back",
      onClick: () => {
        history.goBack();
      },
    }),
    [history]
  );

  // Effettua la validazione del form e restituisce un oggetto contenente l'esito della validazione.
  const validateForm = () => {
    return formRef.current.instance.validate();
  };

  // Effettua il render del titolo della toolbar
  const renderTitle = () => {
    let viewTitle = title;
    if (!viewTitle) {
      viewTitle = viewTitle = entityTitleMap[entity] || "";
    }
    return (
      <div className="toolbar-label" style={{ fontSize: "2.5rem" }}>
        {viewTitle}
      </div>
    );
  };

  // Collection dei children da utilizzare successivamente.
  const childrenArr = React.Children.toArray(children);
  // Collection delle colonne da renderizzare nella datagrid.
  const EditForm = useMemo(
    () => childrenArr.find((comp) => comp.type === EntityEditPage.EditForm),
    [childrenArr]
  );
  const ToolbarItems = useMemo(
    () => childrenArr.find((comp) => comp.type === EntityEditPage.ToolbarItems),
    [childrenArr]
  );
  const ChildrenItems = useMemo(() =>
    childrenArr.filter(
      (comp) =>
        [EntityEditPage.EditForm, EntityEditPage.ToolbarItems].includes(
          comp.type
        ) === false
    )
  );

  if (loading) {
    return <LoadPanel visible />;
  } else if (error) {
    return <ErrorPage message={error.message} />;
  } else if (data && !datarow) {
    setDatarow(data);
    if (typeof onDatarowReady === "function") {
      onDatarowReady(data);
    }
    return <Fragment>Caricando...</Fragment>;
  } else if (data && datarow) {
    const __f = data["flag_eliminato"];
    const flagEliminato = __f !== null && __f !== undefined && __f === true;
    return (
      <Fragment>
        <div className="content-header">
          <Toolbar style={{ backgroundColor: "transparent" }}>
            <Item
              location="before"
              locateInMenu="never"
              widget="dxButton"
              options={backButtonOptions}
            />
            {!currentRoute.readOnly && (
              <Item
                location="after"
                locateInMenu="never"
                widget="dxButton"
                options={saveButtonOptions}
              />
            )}
            {!currentRoute.readOnly && (
              <Item
                location="after"
                locateInMenu="auto"
                widget="dxButton"
                visible={data[entityKey] > 0 && !flagEliminato}
                options={deleteButtonOptions}
              />
            )}
            {!currentRoute.readOnly && (
              <Item
                location="after"
                locateInMenu="auto"
                widget="dxButton"
                visible={flagEliminato}
                options={restoreButtonOptions}
              />
            )}
            <Item location="center" locateInMenu="never" render={renderTitle} />
            {ToolbarItems?.props?.children}
          </Toolbar>
        </div>
        <div className="dx-card form-card">
          <Form
            colCount={4}
            formData={datarow}
            id="form"
            ref={formRef}
            readOnly={currentRoute.readOnly}
          >
            {EditForm?.props?.children(datarow, setDatarowField).props.children}
          </Form>
        </div>
        {ChildrenItems}
        {confirmDeleteModal}
        {confirmRestoreModal}
        {alertModal}
      </Fragment>
    );
  }
}

EntityEditPage.ToolbarItems = ({ children }) => null;
EntityEditPage.EditForm = ({ children }) => null;
