import React, { useState, useRef } from 'react';

import shortid from 'shortid';
import { Button, List } from 'devextreme-react';

import { Toast } from '@/ui/utils';

export default function EntityAllegatiList(props) {
  const {
    items, setItems, multiple = false,
    readOnly = false, disabled = false,
    acceptedMimeTypes = ['*']
  } = props;

  const listRef = useRef();
  const inputRef = useRef();

  const onFilesChanged = async ($event) => {
    if ($event && $event.target && $event.target.files) {
      const files = $event.target.files;
      const newFiles = [];
      for (let file of files) {
        const fileReader = new FileReader();
        const newFile = await new Promise((resolve, reject) => {
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                const fRecord = {};
                fRecord.eliminato = fRecord.flag_eliminato = false;
                fRecord.filename = file.name;
                fRecord.dimensione = file.size;
                fRecord.base64 = fileReader.result.split(',')[1];
                resolve(fRecord);
            };
            fileReader.onerror = (evt) => {
                reject(evt);
            };
        });
        newFiles.push(newFile);
      }
      setItems([
        ...(items || []),
        ...newFiles
      ]);
    }
  };

  const deleteItem = (evt, item) => {
    if ((item.id === 0 || !item.id)) {
      items.splice(
        items.indexOf(
          items.find(({uuid}) => uuid === item.uuid)
        ),
        1
      );
    }
    else {
      item.eliminato = item.flag_eliminato = true;
    }
    setItems(items);
    listRef.current.instance.reload();
  }

  const restoreItem = (evt, item) => {
    item.eliminato = item.flag_eliminato = false;
    setItems(items);
    listRef.current.instance.reload();
  }

  const downloadItem = (evt, item) => {
    const downloadUrl = `${process.env.REACT_APP_SVC_BASE_URL}/download.aspx?u=${item.id}`;
    window.location.href = downloadUrl;
    Toast('info', 'Download avviato! Se non trovi il file scaricato, verifica nella sezione download del tuo browser.', 4000);
  };

  function ItemTemplate(item) {
    if (!item.uuid) item.uuid = shortid();
    return (
      <div style={{textDecoration: item.eliminato ? 'line-through' : ''}} key={item.uuid}>
        <span>{item.filename}</span>
        {item.eliminato && '(eliminato)'}
        
        <Button
          visible={!item.eliminato}
          style={{ float: 'right' }}
          icon='trash'
          onClick={(evt) => deleteItem(evt, item)}
        />
        <Button
          visible={item.eliminato}
          style={{ float: 'right' }}
          icon='refresh'
          type='success'
          text='Ripristina'
          onClick={(evt) => restoreItem(evt, item)}
        />
        <Button
          visible={item.id > 0}
          style={{ float: 'right' }}
          icon='fa fa-cloud-download'
          onClick={(evt) => downloadItem(evt, item)}
        />
      </div>
    );
  }

  return <>
    <input 
      ref={inputRef}
      disabled={readOnly || disabled}
      type="file" 
      style={{display: 'none'}}
      onChange={($event) => onFilesChanged($event)}
      accept={acceptedMimeTypes}
      multiple={multiple} />
    <List
      ref={listRef}
      keyExpr='uuid'
      dataSource={items}
      height='auto'
      itemRender={ItemTemplate}
    />
    <Button
      text='Aggiungi un file da caricare'
      type='success'
      icon='fa fa-cloud-upload'
      onClick={() => inputRef.current.click()}
    />
  </>;
}