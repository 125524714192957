import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LoadPanel } from "devextreme-react/load-panel";
import { Column } from "devextreme-react/data-grid";
import Tabs from "devextreme-react/tab-panel";

import {
  CardFilters,
  EntityDataGrid,
  FiltersRenderer,
  useSvcCustomRequest,
} from "@/svc";

export default function CorsiScadenzePage(opts = {}) {
  const { filtri } = opts;
  // pagina custom che non segue nessuna logica condivisa con le altre pagine.
  const [scadenzeFuture, setScadenzeFuture] = useState([]);
  const [scadenzePassate, setScadenzePassate] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [localFiltri, setLocalFiltri] = useState({});

  const displayFilterBox = useMemo(() => {
    return !Boolean(filtri);
  }, [filtri]);

  const { customRequest } = useSvcCustomRequest("Core", "contattiCorsi");

  const onOptionChanged = useCallback((evt) => {
    if (evt.name === "selectedIndex") {
      setSelectedTabIndex(evt.value);
    }
  }, []);

  const tabItems = useMemo(() => ["Scadenze future", "Scadenze passate"], []);

  const displayScadenzeFuture = useMemo(
    () => selectedTabIndex === 0,
    [selectedTabIndex]
  );
  const displayScadenzePassate = useMemo(
    () => selectedTabIndex === 1,
    [selectedTabIndex]
  );

  const entityDataGridDataSource = useMemo(() => {
    let dataSource = [];
    if (displayScadenzePassate) {
      dataSource = scadenzePassate;
    } else if (displayScadenzeFuture) {
      dataSource = scadenzeFuture;
    }
    return dataSource;
  }, [
    displayScadenzeFuture,
    displayScadenzePassate,
    scadenzeFuture,
    scadenzePassate,
  ]);

  const loadData = useCallback(async () => {
    setLoading(true);
    const res = await customRequest({
      command: "Scadenze",
      filtri: {
        ...(filtri ?? {}),
        ...(localFiltri ?? {}),
      },
    });
    const { contattiCorsiScadenze } = res;
    // console.log("contattiCorsiScadenze is", contattiCorsiScadenze);
    setScadenzeFuture(contattiCorsiScadenze.scadenzeFutureList);
    setScadenzePassate(contattiCorsiScadenze.scadenzePassateList);
    setLoading(false);
  }, [customRequest, filtri, localFiltri]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Fragment>
      {displayFilterBox && (
        <FiltersRenderer
          component={CardFilters}
          filters={localFiltri}
          items={{
            clientiRecord: {
              initialValue: null,
            },
            maxEntries: {
              initialValue: void 0,
            },
            corsiRecord: {
              initialValue: null,
            },
            giorni: {
              initialValue: null,
            },
          }}
          updateFilters={setLocalFiltri}
        />
      )}

      <Tabs
        items={tabItems}
        selectedIndex={selectedTabIndex}
        onOptionChanged={onOptionChanged}
      >
        <div className="content"></div>
      </Tabs>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{
          at: "center",
          of: "body",
        }}
        visible={loading}
        showIndicator={true}
        closeOnOutsideClick={false}
      />
      <EntityDataGrid
        entity="scadenzeFutureList"
        allowUpdating={false}
        dataSource={entityDataGridDataSource}
      >
        <Column caption="Corso" dataField="titolo" dataType="string" />
        {/*  
        <Column
          caption='Scadenza'
          dataField='scadenza'
          dataType='string'
          />
        <Column
          caption='Inizio'
          dataField='inizio'
          dataType='date' />
        <Column
          caption='Fine'
          dataField='fine'
        dataType='date' />*/}
        <Column caption="Scadenza" dataField="inizio" dataType="date" />
        <Column
          caption="Email contatto"
          dataField="contattoEmail"
          dataType="string"
        />
      </EntityDataGrid>
    </Fragment>
  );
}
