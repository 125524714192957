import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Column, Lookup, RequiredRule } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/toolbar';

import { EntityListPage, useSvcLookupDataGridColumnStore, useSvcCustomRequest, useSvcAziendaType, SvcContext } from '@/svc';
import { useModalConfirm } from '@/ui/components';
import { Toast } from '@/ui/utils';

function formatTariffaBase({ value }) {
  if (typeof value !== 'number') {
    return '-';
  }
  
  return `€ ${value.toFixed(2)}`;
}

export default function ListinoPeriodiPage() {
  const { aziendaAs, user } = useContext(SvcContext);
  const [ listiniSyncLoading, setListiniSyncLoading ] = useState(false);

  const [ confirmPublishModal, userWantToPublish ] = useModalConfirm({
    title: 'Attenzione',
    question: 'Premendo "Sì" partirà la procedura di pubblicazione dei listini.'
      + ' Vuoi continuare?'
  });

  const { customRequest } = useSvcCustomRequest('Book', 'listinoPeriodi');
  const syncListini = useCallback(() => {
    return customRequest({
      command: 'Sync'
    });
  }, [ customRequest ]);
  
  const syncListiniButtonOptions = useMemo(() => ({
    text: 'Pubblica sul sito',
    type: 'success',
    stylingMode: 'outlined',
    onClick: async ({ component }) => {
      if (user?.IsAdmin && !aziendaAs) {
        Toast('error', 'Per pubblicare i listini devi prima impersonificare un\'azienda.', 4000);
        return;
      }
      
      if (!await userWantToPublish()) {
        return;
      }

      const buttonInstance = component.instance();
      try {
        buttonInstance.option('disabled', true);
        buttonInstance.option('text', void 0);
        buttonInstance.option('icon', 'fa fa-spinner fa-spin');

        setListiniSyncLoading(true);
        const allineamentoListiniResult = await syncListini();
        setListiniSyncLoading(false);
        // NB: non è necessario verificare l'esito, perché finisce in automatico nel catch nel caso in cui sia esito = false.
        Toast('success', 'Procedura terminata con successo.');
      }
      catch (e) {
        Toast('error', 'Si è verificato un errore imprevisto. Contattare l\'assistenza comunicando l\'errore: ' + e.message, 8000);
      }
      buttonInstance.option('disabled', false);
      buttonInstance.option('text', 'Pubblica sul sito');
      buttonInstance.option('icon', void 0);
      
    }
  }), [aziendaAs, syncListini, user.IsAdmin, userWantToPublish]);

  // Stabilisce se l'azienda attuale gestisce i servizi o meno.
  const { isServizio } = useSvcAziendaType();

  // datasources
  const tipoCameraAziendaDataSource = useSvcLookupDataGridColumnStore({
    domain: 'Book',
    entity: 'tipoCameraAzienda',
    lookupKey: 'id'
  });

  const serviziPrenotazioneAziendeDataSource = useSvcLookupDataGridColumnStore({
    domain: 'Book',
    entity: 'serviziPrenotazioneAziende',
    lookupKey: 'id'
  });

  /* const trattamentoPrenotazioneDataSource = useSvcDatasource({
    domain: 'Book',
    entity: 'trattamentoPrenotazione',
    lookupKey: 'codice'
  }); */

  // Contiene le colonne da mostrare in coda nel caso dei servizi.
  const servicesColumns = (
    <Column
      caption='Servizio'
      dataField='servizioAzienda.id'>
        <Lookup
          dataSource={serviziPrenotazioneAziendeDataSource}
          displayExpr="servizio.displayText"
          valueExpr="id" />
        <RequiredRule />
    </Column>
  );

  // contiene le colonne da mostrare in coda nel caso di strutture.
  const stucturesColumns = (
    <Column
      caption='Tipo Camera'
      dataField='tipoCameraAzienda.id'>
        <Lookup
          dataSource={tipoCameraAziendaDataSource}
          displayExpr="tipoCamera.displayText"
          valueExpr="id" />
        <RequiredRule />
    </Column>
  );

  return (
    <>
      <EntityListPage
        domain='Book'
        entity='listinoPeriodi'
        editRoute='/book/ListinoPeriodi/edit'>
          <EntityListPage.ToolbarItems>
            <Item 
              location="after"
              locateInMenu="never"
              widget="dxButton"
              options={syncListiniButtonOptions} />
          </EntityListPage.ToolbarItems>
          <EntityListPage.Columns>
            <Column
              caption='Inizio'
              dataField='data_inizio'
              dataType='date'
              >
                <RequiredRule />
            </Column>

            <Column
              caption='Fine'
              dataField='data_fine'
              dataType='date'>
                <RequiredRule />
            </Column>

            {isServizio ? servicesColumns : stucturesColumns}
            
            <Column
              caption='Tariffa 1 persona'
              dataField='tariffa_1'
              dataType='number'
              formItem={{
                visible: false
              }}
              customizeText={formatTariffaBase}>
            </Column>
            <Column
              caption='Tariffa Base'
              dataField='tariffa_base'
              dataType='number'
              customizeText={formatTariffaBase}>
                <RequiredRule />
            </Column>
            <Column
              caption='Tariffa 3 persone'
              dataField='tariffa_3'
              dataType='number'
              formItem={{
                visible: false
              }}
              customizeText={formatTariffaBase}>
            </Column>
            <Column
              caption='Tariffa 4 persone'
              dataField='tariffa_4'
              dataType='number'
              formItem={{
                visible: false
              }}
              customizeText={formatTariffaBase}>
            </Column>
            <Column
              caption='Trattamento'
              dataField='trattamento.descrizione'
              dataType='string'
              formItem={{
                visible: false
              }}>
            </Column>
          </EntityListPage.Columns>
      </EntityListPage>
      {confirmPublishModal}
    </>
  );
}