import { useMemo } from 'react';
import { useSvcDatasource } from '.';

export default function useSvcLookupDataGridColumnStore(props) {
  // const { domain, entity, filters, lookupKey = 'id' } = props;
  const dataSource = useSvcDatasource(props);

  const store = useMemo(() => {
    return dataSource.store();
  }, [ dataSource ]);

  return store;
}
