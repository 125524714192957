import React from 'react';

import { EmptyItem, SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcDatasource } from '@/svc';
import { TreeSelectBox } from '@/ui/components';
import { useEnv } from '@/ui/hooks';

const clienteAlias = (process.env.REACT_APP_ENTITY_CLIENTE_ALIAS || 'Cliente');

export default function ClientiCategorieEditPage() {
  const { 
    clientiCategorieUseFlagImportante,
    clientiCategorieUseTreelist,
    clientiCategorieUseCodiceGestionale
  } = useEnv();

  const clientiCategorieDataSource = useSvcDatasource({
    domain: 'Core',
    entity: 'clientiCategorie'
  });

  return <EntityEditPage
    domain='Core'
    entity='clientiCategorie'
    >
      <EntityEditPage.EditForm>
        {(datarow, setDatarowField) => {
          return (
            <React.Fragment>
              <SimpleItem
                visible={clientiCategorieUseCodiceGestionale}
                dataField="codice_gestionale"
                editorType="dxNumberBox"
                editorOptions={{ readOnly: true }}>
              </SimpleItem>
              <SimpleItem dataField="descrizione" editorType="dxTextBox">
                <RequiredRule />
              </SimpleItem>
              <SimpleItem>
                  <Label text="Padre" />
                  <TreeSelectBox
                    dataSource={clientiCategorieDataSource}
                    value={datarow.padre}
                    onValueChanged={setDatarowField('padre')} />
              </SimpleItem>
              <EmptyItem colSpan={1}></EmptyItem>
              <SimpleItem
                visible={clientiCategorieUseFlagImportante}
                dataField="flag_importante"
                editorType="dxCheckBox"
              >
                <Label text="Importante" />
              </SimpleItem>
            </React.Fragment>
          );
        }}
      </EntityEditPage.EditForm>
  </EntityEditPage>
}