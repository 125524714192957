import { useCallback, useContext } from 'react';

import { SvcContext } from '@/svc';

export default function useSvcSave(domain, entity, { customSaveRecordKey, customSaveCommandValue }) {
  const { client, user } = useContext(SvcContext);

  const token = user.token_login;

  const saveEntity = useCallback(
    (record) => client.saveEntity(domain, entity, record, { token, customSaveRecordKey, customSaveCommandValue }),
    [ client, domain, entity, token, customSaveCommandValue ]
  );

  return saveEntity;
}