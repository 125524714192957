import React, { useCallback, useEffect, useState } from 'react';

import { LoadPanel, Scheduler } from 'devextreme-react';

import { useSvcList } from '@/svc';
import { ErrorPage } from '@/ui/pages';

export default function EntityScheduler(props) {
  const {
    domain, entity, filters,
    transformDataSource,
    appointmentComponent, appointmentTooltipComponent,
    initialDate = new Date(),
    startDateExpr = 'startDate', endDateExpr = 'endDate'
  } = props;

  const onAppointmentFormOpening = useCallback(e => {
    e.cancel = true;
  }, []);

  const [ view, setView ] = useState('month');
  const storageKey = `schedulerView-${domain}${entity}`;
  useEffect(() => {
    const storedView = window.localStorage.getItem(storageKey);
    if (storedView) {
      setView(storedView);
    }
  }, [ storageKey, setView ]);
  useEffect(() => {
    if (view) {
      window.localStorage.setItem(storageKey, view);
    } else {
      window.localStorage.removeItem(storageKey);
    }
  }, [ storageKey, view ]);

  const [ currentDate, setCurrentDate ] = useState(initialDate);
  const onOptionChanged = useCallback(opts => {
    if (opts.name === 'currentView') {
      setView(opts.value);
    } else if (opts.name === 'currentDate') {
      setCurrentDate(opts.value);
    }
  }, []);

  const [ { loading, error, data } ] = useSvcList(
    domain, entity, filters(currentDate, view)
  );

  if (loading) {
    return <LoadPanel visible />;
  } else if (error) {
    return <ErrorPage message={error.message} />;
  } else {
    const editingOpts = {
      allowAdding: false,
      allowDeleting: false,
      allowDragging: false,
      allowResizing: false,
      allowUpdating: false
    };

    const dataSource = typeof transformDataSource === 'function'
      ? transformDataSource(data)
      : data;

    return (
      <Scheduler
        dataSource={dataSource}
        currentView={view}
        currentDate={currentDate}
        appointmentComponent={appointmentComponent}
        appointmentTooltipComponent={appointmentTooltipComponent}
        onAppointmentFormOpening={onAppointmentFormOpening}
        onOptionChanged={onOptionChanged}

        // Hard-coded props
        editing={editingOpts}
        views={[ 'agenda', 'month' ]}
        startDateExpr={startDateExpr}
        endDateExpr={endDateExpr} />
    );
  }
}
