import React from 'react';

import SelectBox from 'devextreme-react/select-box';

import { useSvcSelectBoxConfig } from '@/svc/hooks';

export default function SvcSelectBox(props) {
  const {
    domain, entity, paginate = true, filtri = null,
    value, setValue,
    editorOptions = {}
  } = props;

  const svcSelectBoxConfig = useSvcSelectBoxConfig({
    domain,
    entity,
    paginate,
    filters: filtri
  });

  return <>
    <SelectBox
      value={value}
      onValueChanged={(newValue) => setValue(newValue)}
      {
        ...{
          ...svcSelectBoxConfig,
          ...editorOptions
        }
      }
    />
  </>
}
