import { useMemo, useState } from 'react';

const formats = {
  '€': '€ #,##0.##',
  '%': '#0 %'
}

export default function useCurrencyPercentNumberBox(initialUnit = '%') {
  const [ format, setFormat ] = useState(formats[initialUnit]);
  const [ unit, setUnit ] = useState(initialUnit === '€' ? '%' : '€');
  
  return useMemo(() => {
    const buttonOptions = {
      text: unit,
      hint: 'Clicca per esprimere il valore in ' + unit,
      stylingMode: 'text',
      width: 16,
      onClick() {
        setFormat(formats[unit]);

        if (unit === '€') {
          setUnit('%');
        } else if (unit === '%') {
          setUnit('€');
        }
      }
    };

    return {
      format,
      onInitialized: ({ component }) => {
        const currentValue = component.option('value');
        if (currentValue > 0 && currentValue <= 1) {
          setFormat(formats['%']);
          setUnit('€');
        }
        else {
          setFormat(formats['€']);
          setUnit('%');
        }
      },
      buttons: [{
        name: 'format',
        location: 'after',
        options: buttonOptions
      }]
    };
  }, [ format, setFormat, unit, setUnit ]);
}