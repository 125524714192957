import React from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcDatasource } from '@/svc';

export default function CorsiEditPage() {
  const corsiTipologiaDatasource = useSvcDatasource({
    domain: 'Xtra',
    entity: 'corsiTipologia'
  })

  const titolariDatasource = useSvcDatasource({
    domain: 'Xtra',
    entity: 'titolariCorsi'
  })

  return (
    <EntityEditPage
      domain='Xtra'
      entity='corsi'>
        <EntityEditPage.EditForm>
          {(datarow) => (
            <React.Fragment>
              <SimpleItem
                colSpan={2}
                dataField="nome"
                editorType="dxTextBox"
              >
                <Label text="Nome" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                colSpan={2}
                dataField="descrizione"
                editorType="dxTextBox"
              >
                <Label text="Descrizione" />
              </SimpleItem>
              <SimpleItem
                dataField="durata_ore"
                editorType="dxNumberBox"
              >
                <Label text="Durata Ore" />
              </SimpleItem>
              <SimpleItem
                dataField="periodicita_mesi"
                editorType="dxNumberBox"
              >
                <Label text="Periodicità Mesi" />
              </SimpleItem>
              <SimpleItem
                dataField='tipologia'
                editorType='dxSelectBox'
                editorOptions={{
                  dataSource: corsiTipologiaDatasource,
                  displayExpr: 'displayText'
                }}>
                  <Label text='Tipologia' />
              </SimpleItem>
              <SimpleItem
                dataField='titolare'
                editorType='dxSelectBox'
                editorOptions={{
                  dataSource: titolariDatasource,
                  displayExpr: 'displayText'
                }}>
                  <Label text='Titolare' />
              </SimpleItem>
              <SimpleItem
                colSpan={2}
                dataField="nome_per_sito"
                editorType="dxTextBox"
              >
                <Label text="Nome per sito" />
              </SimpleItem>
            </React.Fragment>
          )}
        </EntityEditPage.EditForm>
    </EntityEditPage>
  );
}