import React from 'react';

import { HashRouter as Router } from 'react-router-dom';
import { locale, loadMessages } from 'devextreme/localization';

import { SvcClient, SvcProvider } from '@/svc';
import itMessages from '@/localization/it.json';
import { NavProvider } from '@/navigation';
import UI from '@/ui';
import { routes } from '@/ui/pages';
import staticRoutes from '@/staticRoutes.js';

const homeRoute = process.env.REACT_APP_DEFAULT_REDIRECT_TO;
const routeFilters = routes.map(r => r.path);
const svcBaseUrl = process.env.REACT_APP_SVC_BASE_URL;

const svcClient = new SvcClient({
  url: svcBaseUrl,
  headers: {},
  body: {}
});

export default function App() {
  loadMessages(itMessages);
  locale(navigator.language);

  return (
    <Router>
      <SvcProvider client={svcClient}>
        <NavProvider filters={routeFilters} homeRoute={homeRoute} staticRoutes={staticRoutes}>
          <UI />
        </NavProvider>
      </SvcProvider>
    </Router>
  );
}
