import React from 'react';
import { createPortal } from 'react-dom';

import shortid from 'shortid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import { appName as defaultTitle } from '@/ui/config';

export default function Modal({ children, title, visible, onClose, minWidth = 240, displayCloseButton = true }) {
  const childrenArr = React.Children.toArray(children);

  const titleComp = childrenArr.find(comp => comp.type === Modal.Title);
  const buttonComps = childrenArr.filter(comp => comp.type === Modal.Button);
  const body = childrenArr.filter(comp => {
    return comp.type !== Modal.Button && comp.type !== Modal.Title
  });

  return createPortal(
    <Popup
      title={(titleComp && titleComp.props.children) || title || defaultTitle}
      visible={visible}

      // Hard-coded settings
      closeOnOutsideClick={false}
      showCloseButton={buttonComps?.length === 0 && displayCloseButton}
      dragEnabled
      showTitle
      minWidth={minWidth}
      width='auto'
      height='auto'
    >
      <ScrollView>
        <div style={{ margin: '10px 0' }}>{body}</div>
      </ScrollView>
      {buttonComps.map(({ props }) => (
        <ToolbarItem
          key={shortid.generate()}
          location='after'
          options={{
            disabled: props.disabled,
            type: props.type,
            text: props.children,
            onClick: props.onClick
          }}
          toolbar='bottom'
          widget='dxButton'
        />
      ))}
    </Popup>,
    document.body
  );
}

Modal.Title = ({ children }) => {
  // This has been done because Modal.Title's children will be remapped to
  // DevExtreme's Popup component, so no need to render this. 
  return null;
}

Modal.Button = ({ children, onClick }) => {
  // This has been done because DevExtreme wants only direct ToolbarItem
  // components as Buttons, custom components like this one will not be
  // recognized, that's why we will map this component to ToolbarItem during
  // Modal rendering. 
  return null;
}