import React, { useCallback, useMemo, useState, useRef } from 'react';

import { ButtonItem, ButtonOptions, Form, Label, SimpleItem, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcCustomRequest } from '@/svc';
import { Toast } from '@/ui/utils';

export default function AziendeEditPage() {
  const [datiAzienda, setDatiAzienda] = useState(null);
  const [datiAziendaSaving, setDatiAziendaSaving] = useState(false);
  const datiAziendaFormRef = useRef();
  const { customRequest } = useSvcCustomRequest('Auth', 'datiAzienda');

  const onDatarowReady = useCallback(async (datarow) => {
    if (datarow.id > 0) {
      const { datiAziendaRecord } = await customRequest({
        command: 'get',
        key: datarow.id
      });
      setDatiAzienda(datiAziendaRecord);
    }
  }, [customRequest]);

  // Contiene la definizione dell'intero box "dati azienda".
  const datiAziendaEditBox = useMemo(() => {
    const saveDatiAzienda = async () => {
      const isValid = datiAziendaFormRef.current.instance.validate();
      if (isValid) {
        try {
          setDatiAziendaSaving(true);
          const saveResult = await customRequest({
            command: 'save',
            datiAziendaRecord: datiAzienda
          });
          if (saveResult && saveResult.esito === true) {
            Toast("success", "Salvataggio avvenuto con successo!");
          }
        }
        catch (e) {
          Toast("error", "Si è verificato un errore in fase di salvataggio dei dati azienda.");
        }
        finally {
          setDatiAziendaSaving(false);
        }
      }
    }

    return (
      <div className='dx-card form-card'>
        <Form
          colCount={4}
          formData={datiAzienda}
          ref={datiAziendaFormRef}
        >
          <SimpleItem colSpan={4} dataField="email" editorType="dxTextBox">
            <Label text="Email" />
          </SimpleItem>
          <ButtonItem alignment="left">
            <ButtonOptions
              type="success"
              disabled={datiAziendaSaving}
              text={datiAziendaSaving ? undefined : 'Aggiorna i dati azienda'}
              icon={datiAziendaSaving ? 'fa fa-spinner fa-spin' : undefined}
              onClick={saveDatiAzienda} />
          </ButtonItem>
        </Form>
      </div>
    );
  }, [ customRequest, datiAzienda, datiAziendaSaving ]);
  
  return <EntityEditPage
    domain='Auth'
    entity='aziende'
    onDatarowReady={onDatarowReady}
    >
      <EntityEditPage.EditForm>
        {(datarow) => {
          return (
            <React.Fragment>
              <SimpleItem colSpan={2} dataField="ragionesociale" editorType="dxTextBox">
                <Label text="Ragione Sociale" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem dataField="tipo" editorType="dxSelectBox" editorOptions={{
                dataSource: [
                  {id: 1, nome: 'Hotel'},
                  {id: 2, nome: 'BB'},
                  {id: 3, nome: 'Appartamenti'},
                  {id: 100, nome: 'Scuola di sci'},
                  {id: 101, nome: 'Noleggio'},
                  {id: 102, nome: 'Ciaspolata'}
                ],
                displayExpr: "nome",
                valueExpr: "id"
              }}>
                <Label text="Tipo" />
                <RequiredRule />
              </SimpleItem>
            </React.Fragment>
          );
        }}
      </EntityEditPage.EditForm>
      {datiAzienda && datiAziendaEditBox}
  </EntityEditPage>
}