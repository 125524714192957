import React, { useCallback, useRef, useState } from 'react';

import Modal from './modal';

export default function useModalConfirm(opts = {}) {
  let title, question;

  if (typeof opts === 'string') {
    question = opts;
  } else {
    title = opts.title;
    question = opts.question;
  }

  const [ visible, setVisible ] = useState(false);
  const resolveRef = useRef();

  const openModal = useCallback(() => {
    setVisible(true);

    return new Promise(resolve => resolveRef.current = resolve);
  }, [ setVisible ]);

  const closeWithResult = (res) => {
    setVisible(false);
    resolveRef.current(res);
  }

  const closeModal = useCallback((value) => {
    setVisible(false);

    resolveRef.current(value)
  }, [ setVisible ]);

  const modal = (
    <Modal
      title={title}
      visible={visible}
      onClose={closeModal}
    >
      {question}
      <Modal.Button onClick={() => closeWithResult(false)}>No</Modal.Button>
      <Modal.Button onClick={() => closeWithResult(true)}>Sì</Modal.Button>
    </Modal>
  );

  return [ modal, openModal ];
}