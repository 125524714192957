import React, { useMemo } from 'react';

import { CheckBox } from 'devextreme-react';

export default function FlagsListComponent(props) {
  const { items, setItems, direction = 'column' } = props;

  const onCheckboxValueChanged = ({ value }, item) => {
    item.selezionato = value;
    setItems(items);
  }

  const computedStyles = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: direction,
      gap: '10px',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    }
  }, [direction]);

  if (items) {
    return (
      <React.Fragment>
        <div style={computedStyles}>
          {items.map((item) => (
              <CheckBox
                key={item.codice}
                text={item.descrizione}
                value={item.selezionato}
                onValueChanged={(evt) => onCheckboxValueChanged(evt, item)}
              />
          ))}
        </div>
      </React.Fragment>
    );
  }
  return <React.Fragment>Caricamento...</React.Fragment>;
}
