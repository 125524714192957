import React, { useMemo } from 'react';

import { SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcDatasource } from '@/svc';
import useCurrencyPercentNumberBox from '@/ui/hooks/useCurrencyPercentNumberBox';

export default function ListinoTipoCameraAziendaEditPage() {
  const tipoCameraAziendaDataSource = useSvcDatasource({
    domain: 'Book',
    entity: 'tipoCameraAzienda',
    lookupKey: 'id'
  });

  const tipoCameraAziendaSelectBox = useMemo(() => ({
    dataSource: tipoCameraAziendaDataSource,
    displayExpr: 'tipoCamera.displayText'
  }), [ tipoCameraAziendaDataSource ]);

  const kids36NumberBox = useCurrencyPercentNumberBox();
  const kids612NumberBox = useCurrencyPercentNumberBox();
  const adults3NumberBox = useCurrencyPercentNumberBox();
  const adults4NumberBox = useCurrencyPercentNumberBox();
  const dusNumberBox = useCurrencyPercentNumberBox();
  
  return (
    <EntityEditPage
      domain='Book'
      entity='listinoTipoCameraAzienda'>
        <EntityEditPage.EditForm>
          {(data) => (
            <React.Fragment>
              <SimpleItem
                dataField='valido_da'
                editorType='dxDateBox'>
                  <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField='tipoCameraAzienda'
                editorType='dxSelectBox'
                editorOptions={tipoCameraAziendaSelectBox}>
                  <Label text='Tipo Camera' />
                  <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField='eta_gratis'
                editorType='dxNumberBox'>
                  <Label text='Età fascia gratis' />
                  <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField='eta_bambino'
                editorType='dxNumberBox'>
                  <Label text='Età bambini' />
                  <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField='eta_ragazzo'
                editorType='dxNumberBox'>
                  <Label text='Età ragazzi' />
                  <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField='bambino_3_6'
                editorType='dxNumberBox'
                editorOptions={kids36NumberBox}>
                  <Label text='Riduzione bambini' />
                  <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField='bambino_6_12'
                editorType='dxNumberBox'
                editorOptions={kids612NumberBox}>
                  <Label text='Riduzione ragazzi' />
                  <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField='riduzione_3'
                editorType='dxNumberBox'
                editorOptions={adults3NumberBox}>
                  <Label text='Riduzione 3° adulto' />
                  <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField='riduzione_4'
                editorType='dxNumberBox'
                editorOptions={adults4NumberBox}>
                  <Label text='Riduzione 4° adulto' />
                  <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField='opzione_dus'
                editorType='dxNumberBox'
                editorOptions={dusNumberBox}>
                  <Label text='Supplemento DUS' />
                  <RequiredRule />
              </SimpleItem>
            </React.Fragment>
          )}
        </EntityEditPage.EditForm>
    </EntityEditPage>
  );
}