import { SvcError } from '@/svc';

export default class SvcClient {
  /**
   * Creates an instance of SvcClient.
   * @param {{
   *  url: string,
   *  aziendaAs: object,
   *  useTokenHeader: boolean,
   *  body: object,
   *  headers: object
   * }} [config]
   * @memberof SvcClient
   */
  constructor(config = {}) {
    if (!config.url) {
      throw new Error('SvcClient: config.url is required');
    }

    this.config = config;
    this.url = config.url.endsWith('/') ? config.url : config.url + '/';
    this.aziendaAs = config.aziendaAs || null;
    this.useTokenHeader = config.useTokenHeader || true;
    this.body = config.body || {};
    this.headers = {
      'Content-Type': 'application/json',
      ...config.headers
    };
  }

  logIn(username, password) {
    const body = {
      action: 'Login',
      datiLogin: { username, password }
    };

    return this._request('Auth', 'Controller', body);
  }

  async logOut(token) {}

  // DEPRECATED: Use `user.routes` received by `client.logIn` method.
  async fetchNavTree(token) {
    console.warn('DEPRECATED: Use `user.routes` received by `client.logIn` method.');

    const body = { token_login: token };
    const modules = await this._request('App', 'Modules', body, {
      wrapInData: false,
      ignoreMissingEsito: true
    });
    return modules;
  }

  listEntity(domain, entity, filters, opts = {}) {
    const body = {
      command: opts.command ?? 'list',
      entity,
      filtri: filters
    };

    return this._request(domain, 'Controller', body, opts)
      .then(data => [ data, data[opts.responseField ?? `${entity}List`] ]);
  }

  getEntity(domain, entity, key, opts = {}) {
    const body = {
      command: 'get',
      entity,
      key,
    };

    return this._request(domain, 'Controller', body, opts)
      .then(data => data[`${entity}Record`]);
  }

  cloneEntity(domain, entity, key, opts = {}) {
    const body = {
      command: 'clone',
      entity,
      key,
    };

    return this._request(domain, 'Controller', body, opts)
      .then(data => data[`${entity}Record`]);
  }

  downloadFile(fileName) {
    window.location.href = `${this.url}download.aspx?f=${fileName}`;
  }

  exportEntity(domain, entity, opts = {}) {
    const body = {
      command: 'export',
      entity
    };

    return this._request(domain, 'Controller', body, opts)
      .then(data => [data.fileUrl, data.errore]);
  }

  filtersEntity(domain, entity, opts = {}) {
    const body = {
      command: 'filtriNew',
      entity
    };

    const responseKey = opts.responseKey ?? 'filtriNew';

    return this._request(domain, 'Controller', body, opts)
      .then(data => {
        for (const key of Object.keys(data)) {
          const value = data[key];
          if (value != null && key.includes(responseKey)) {
            return value;
          }
        }

        throw new Error('[SvcClient.Filters] Cannot find filters resp key');
      });
  }

  newEntity(domain, entity, opts = {}) {
    let command = opts.customNewCommandValue || 'new';
    const body = {
      command,
      entity
    };

    return this._request(domain, 'Controller', body, opts)
      .then(data => data[`${entity}Record`]);
  }

  saveEntity(domain, entity, record, opts = {}) {
    let command = opts.customSaveCommandValue || 'save';
    let entityRecordKey = opts.customSaveRecordKey || `${entity}Record`;
    const body = {
      command,
      entity,
      [entityRecordKey]: record
    };

    return this._request(domain, 'Controller', body, opts)
      .then(data => data.entityID);
  }

  deleteEntity(domain, entity, record, opts = {}) {
    const body = {
      command: 'delete',
      entity,
      [`${entity}Record`]: record
    };

    return this._request(domain, 'Controller', body, opts);
  }

  customRequest(domain, entity, body = {}, opts = {}) {
    return this._request(domain, 'Controller', {
      entity,
      ...body
    }, opts);
  }

  async _request(domain, service, body = {}, opts = {}) {
    let {
      method = 'POST',
      aziendaAs = this.aziendaAs,
      token,
      wrapInData = true,
      ignoreMissingEsito = false,
      headers = {}
    } = opts;

    if (token) {
      if (this.useTokenHeader) {
        headers['X-AuthToken'] = token;
      } else {
        body.token_login = token;
      }
    }

    body = { ...this.body, ...body };
    if (aziendaAs) {
      body.aziendaAs = aziendaAs;
    }
    body = wrapInData ? { data: body } : body;

    headers = { ...this.headers, ...headers };

    const url = `${this.url}${domain}.svc/${service}`;
    const endpoint = `${method} ${url}`;

    const fetchOpts = {
      method,
      body: JSON.stringify(body),
      headers
    };
    const res = await fetch(url, fetchOpts);
    const { status } = res;

    try {
      const resBody = await res.json();
      if (res.ok && !resBody) {
        // If res is ok it means that the endpoint actually responded
        // but the received body has an invalid format (thrown by `res.json`).
        throw new SvcError(endpoint, status, 'Received body is not a valid json.');
      }
      if (!ignoreMissingEsito) {
        if (typeof resBody.esito !== 'boolean') {
          throw new SvcError(endpoint, status, 'Missing "esito" property in the response body.');
        }
        if (resBody.esito === false) {
          throw new SvcError(endpoint, status, resBody.errore);
        }
      }

      return resBody;
    } catch (rawErr) {
      let err;

      if (rawErr instanceof SvcError) {
        err = rawErr;
      } else {
        err = new SvcError(endpoint, status, 'C\'è stato un problema nel contattare il server.');
      }

      console.error(err);
      throw err;
    }
  }
}