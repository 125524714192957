import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { useSvcGet } from '@/svc';
import { Button, List, LoadIndicator } from 'devextreme-react';
import { Modal } from '@/ui/components';
import shortid from 'shortid';
import { Toast } from '@/ui/utils';
import Form, { SimpleItem, Label } from 'devextreme-react/form';

function EntityFormInlineListModal(props) {
  const {
    domain, entity, item, defaultNewData = {},
    modalVisible,
    modalTitle, onTriggerModalClose,
    FormItems
  } = props;
  
  const shouldFetch = !item;

  const [{ loading, error, data }, fetchData] = useSvcGet(domain, entity, item?.id, { manual: true });

  const [datarow, setDatarow] = useState(null);
  const [confirmLabel, setConfirmLabel] = useState('Aggiungi');

  if (modalVisible && shouldFetch && (!datarow && !data && !loading && !error)) {
    fetchData();
  }

  const resolveModalValue = (value) => {
    onTriggerModalClose(value);
    setDatarow(null);
    setConfirmLabel('Aggiungi');
  }

  const onConfirmClick = () => {
    resolveModalValue(datarow);
  }

  const onUndoClick = () => {
    resolveModalValue(void 0);
  }

  let modalBody = void 0;
  if (!modalVisible) {
    return <></>;
  }
  if (loading && shouldFetch) {
    modalBody = <LoadIndicator visible />
  }
  else if (shouldFetch && data && !datarow) {
    setDatarow({
      ...data,
      ...defaultNewData
    });
    if (data.id !== 0) {
      setConfirmLabel('Conferma modifica');
    }
  }
  else if (!shouldFetch && !datarow && item) {
    setDatarow(item);
    setConfirmLabel('Conferma modifica');
  }
  else if (error) {
    modalBody = <span>Si è verificato un errore, contattare l'assistenza.</span>;
  }
  else if (datarow) {
    modalBody = <React.Fragment>
        <Form
          colCount={1}
          formData={datarow}
          id="form">
          {FormItems.map(({props}) => {
            return (
              <SimpleItem
                {...props}
              >
                <Label text={props.itemLabel} />
              </SimpleItem>
            )
          })}
        </Form>
    </React.Fragment>
  }
  return <React.Fragment>
    <Modal
        visible={modalVisible}
        minWidth={'40vw'}
      >
      <Modal.Title>
        {modalTitle}
      </Modal.Title>
      <React.Fragment>
        {modalBody}
      </React.Fragment>
      <Modal.Button type="danger" onClick={onUndoClick}>Annulla</Modal.Button>
      <Modal.Button type="success" onClick={onConfirmClick}>{confirmLabel}</Modal.Button>
    </Modal>
  </React.Fragment>;
}

export default function EntityFormInlineList(props) {
  const {
    items = [], setItems,
    domain, entity, defaultNewData = {},
    formTitle, FormItems, ListItemComponent
  } = props;
  if (!ListItemComponent) throw new Error('ListItemComponent mancante.');
  if (!FormItems) throw new Error('FormItems mancante')
  const currentItems = items ?? [];

  const listRef = useRef();
  const [selectedItem, setSelectedItem] = useState(void 0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalKey, _setModalKey] = useState(shortid());
  
  const onAddClick = () => {
    setSelectedItem(void 0);
    setModalVisible(true);
  };

  
  const newButtonComponent = <Button
    icon="add"
    type="success"
    text="Aggiungi"
    onClick={onAddClick}
  />;

  const onListItemClick = ({ itemData }) => {
    if (!modalVisible) {
      setSelectedItem(itemData);
      setModalVisible(true);
    }
  };

  const onModalClosed = useCallback((value) => {
    if (value) {
      // Casistiche:
      // Record esistente (value.id > 0)
      // è necessario cercare e rimpiazzare l'oggetto.
      const itemId = value.id;
      if (itemId && itemId !== 0) {
        const itemRef = currentItems.find(
          ({id}) => id === itemId
        );
        currentItems.splice(
          currentItems.indexOf(itemRef),
          1,
          value
        );
      }
      else if (itemId === 0) {
        // item passato per reference.
        // Se non ha uno shortId assegnato, lo assegno e lo aggiungo.
        if (!value.__shortid) {
          value.__shortid = shortid();
          currentItems.push(value);
        }
        else {
          // Altrimenti, cerco per shortId.
          const itemRef = currentItems.find(
            ({__shortid}) => __shortid === value.__shortid
          );
          currentItems.splice(
            currentItems.indexOf(itemRef),
            1,
            value
          );
        }
      }
      setItems(currentItems);
      listRef.current.instance.reload();
    }
    setSelectedItem(void 0);
    setModalVisible(false);
  }, [currentItems, setItems]);

  const deleteItem = ({event}, item) => {
    event.stopPropagation();
    item.eliminato = true;
    setItems(currentItems);
    Toast('info', 'Elemento contrassegnato come eliminato e verrà definitivamente eliminato al salvataggio. Puoi ripristinarlo cliccando il pulsante "Ripristina".', 6000);
  };

  const restoreItem = ({event}, item) => {
    event.stopPropagation();
    item.eliminato = false;
    setItems(currentItems);
  };

  function ItemTemplate(item) {
    if (!item.uuid) item.uuid = shortid();
    return (
      <div style={{textDecoration: item.eliminato ? 'line-through' : ''}} key={item.uuid}>
        {ListItemComponent(item)}
        <Button
            visible={!item.eliminato}
            style={{ float: 'right' }}
            icon='trash'
            onClick={(evt) => deleteItem(evt, item)}
        />
        <Button
            visible={item.eliminato}
            style={{ float: 'right' }}
            icon='refresh'
            type='success'
            text='Ripristina'
            onClick={(evt) => restoreItem(evt, item)}
        />
      </div>
    );
  }
  
  return <React.Fragment>
    {currentItems && (
      <React.Fragment>
        <List
          ref={listRef}
          keyExpr="uuid"
          dataSource={currentItems}
          height='auto'
          itemRender={ItemTemplate}
          onItemClick={onListItemClick} />
        <br /> 
        <hr />
      </React.Fragment>
    )}
    {!currentItems && (<span>Nessun elemento inserito. <br /></span>)}
    <EntityFormInlineListModal
      key={modalKey}
      domain={domain}
      entity={entity}
      defaultNewData={defaultNewData}
      item={selectedItem}
      modalTitle={formTitle}
      modalVisible={modalVisible}
      onTriggerModalClose={onModalClosed}
      FormItems={FormItems}
    />
    {newButtonComponent}
  </React.Fragment>
}