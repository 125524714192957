import React from 'react';

import { DateBox } from 'devextreme-react';

export default function DataInizio(props) {
  const { value, setValue } = props.data.editorOptions;

  return (
    <DateBox
      value={value}
      onValueChanged={({ value }) => {
        setValue(value);
      }}
      showClearButton={true}
    />
  );
}

DataInizio.key = 'data_inizio';
DataInizio.label = 'Data (dal)';