import React from "react";

import { TextBox } from "devextreme-react";

export default function NomeComuneFilter(props) {
  const { value, setValue } = props.data.editorOptions;

  return (
    <TextBox
      value={value}
      onValueChanged={({ value }) => {
        setValue(value);
      }}
      showClearButton={true}
    />
  );
}

NomeComuneFilter.key = "nomeComune";
NomeComuneFilter.label = "Comune";
