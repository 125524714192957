import './side-navigation-menu.scss';

import React, { useEffect, useRef, useCallback, useContext, useMemo } from 'react';

import * as events from 'devextreme/events';
import TreeView from 'devextreme-react/tree-view';

import { NavContext } from '@/navigation';
import { useScreenSize } from '@/ui/hooks';

export default function SideNavigationMenu(props) {
  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const { navTree, navData } = useContext(NavContext);
  const { isLarge } = useScreenSize();

  const items = useMemo(() => {
    return navTree.map(item => ({ ...item, expanded: isLarge }));
  }, [ navTree, isLarge ]);

  const wrapperRef = useRef();
  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', openMenu);
  }, [ openMenu ]);

  const treeViewRef = useRef();
  const { currentPath } = navData;
  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [ currentPath, compactMode ]);

  return (
    <div
      className='dx-swatch-additional side-navigation-menu'
      ref={getWrapperRef}
    >
      {children}
      <div className='menu-container'>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr='uuid'
          selectionMode='single'
          focusStateEnabled={false}
          expandEvent='click'
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width='100%'
        />
      </div>
    </div>
  );
}
